import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

// css
import './Header.css';

// icons
import { FaBars } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { BsMoon } from 'react-icons/bs';
import { FaRegSun, FaAngleDown, FaAngleUp } from "react-icons/fa";

// logo
import Logo from '../../assest/logo.png';

// flags
import USA from '../../assest/flags/USA-Flag.png';
import TUR from '../../assest/flags/TUR-Flag.png';
import AFG from '../../assest/flags/AFG-Flag.png';

const Header = ({ selectedLanguage, handleLanguageChange}) => {
    const [showNav, setShowNav] = useState('nav_menu');
    const [isButton, setIsButton] = useState(JSON.parse(localStorage.getItem('isButton')) || false);
    const [theme, setTheme] = useState(() => localStorage.getItem('selected_theme') || 'light');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const headerRef = useRef(null);

    useEffect(() => {
        document.body.addEventListener('click', handleBodyClick);

        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, []);

    const handleBodyClick = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
            removeNavMenu();
        }
    };

    const showNavMenu = () => {
        setShowNav('nav_menu show_menu');
    };

    const removeNavMenu = () => {
        setShowNav('nav_menu');
    };

    const changeButton = () => {
        setIsButton(!isButton);
    };

    useEffect(() => {
        localStorage.setItem('isButton', JSON.stringify(isButton));
    }, [isButton]);

    useEffect(() => {
        document.body.classList.toggle('dark_theme', theme === 'dark');
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => {
            const newTheme = prevTheme === 'light' ? 'dark' : 'light';
            localStorage.setItem('selected_theme', newTheme);
            return newTheme;
        });

        changeButton();
    };
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1500) {
                setDropdownOpen(false); // Close dropdown if window is resized below 1500px
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleMouseEnter = () => {
            setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        if (window.innerWidth > 1150) {
            setTimeout(() => {
                if (!document.querySelector('.nav-item.dropdown:hover')) {
                    setDropdownOpen(false);
                }
            }, 1500);
        } else {
            setDropdownOpen(false);
        }
    };

    const HandelClick = () => {
        removeNavMenu();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const location = useLocation();
    const [activeLink, setActiveLink] = useState('/');
    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);
    
    // language selector
    const [isOpen, setIsOpen] = useState(false);
    const selectorRef = useRef(null);

    const languages = [
        { value: 'en', label: 'English', flag: USA },
        { value: 'tr', label: 'Turkish', flag: TUR },
        { value: 'fa', label: 'Dari', flag: AFG }
    ];

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleSelect = (value) => {
        handleLanguageChange(value);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (selectorRef.current && !selectorRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const selectedLanguageObj = languages.find(lang => lang.value === selectedLanguage);

    return (
        <header lang={selectedLanguage === 'fa' ? 'fa' : 'en'} className="header" id="header" ref={headerRef}>
            <nav className="nav container">
                <Link to="/" className="nav_logo" onClick={HandelClick}>
                    <img src={Logo} alt="logo" className="logo_img" />
                </Link>

                <div className={showNav}>
                    <span className="nav_title">
                         {selectedLanguage === 'en' ? 'Menu'
                             : selectedLanguage === 'tr' ? 'Menü'
                                 : selectedLanguage === 'fa' ? 'منو'
                                     : null
                         }
                    </span>

                    <h3 className="nav_name">NACODE</h3>
                    <ul className="nav_list" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                        <li className="nav-item">
                            <Link
                                to='/'
                                onClick={() => {
                                    HandelClick();
                                    setActiveLink('/');
                                }}
                                className={`nav_link ${activeLink === '/' ? 'active_link' : ''}`}
                            >
                                {selectedLanguage === 'en' ? 'Home'
                                    : selectedLanguage === 'tr' ? 'Ev'
                                        : selectedLanguage === 'fa' ? 'صفحه اصلی'
                                            : null
                                }
                            </Link>
                        </li>

                        <li className="nav-item">

                            <Link
                                to='/services'
                                onClick={() => {
                                    HandelClick();
                                    setActiveLink('/services');
                                }}
                                className={`nav_link ${activeLink === '/services' ? 'active_link' : ''}`}
                            >
                                {selectedLanguage === 'en' ? 'Services'
                                    : selectedLanguage === 'tr' ? 'Hizmetler'
                                        : selectedLanguage === 'fa' ? 'خدمات'
                                            : null
                                }
                            </Link>

                        </li>

                        <li className="nav-item">
                            <Link
                                to='/projects'
                                onClick={() => {
                                    HandelClick();
                                    setActiveLink('/projects');
                                }}
                                className={`nav_link ${activeLink === '/projects' ? 'active_link' : ''}`}
                            >
                                {selectedLanguage === 'en' ? 'Projects'
                                    : selectedLanguage === 'tr' ? 'Projeler'
                                        : selectedLanguage === 'fa' ? 'پروژه ها'
                                            : null
                                }
                            </Link>
                        </li>

                        <li className="nav-item dropdown"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>

                            <Link
                                to='/about'
                                onClick={() => {
                                    HandelClick();
                                    setActiveLink('/about');
                                }}
                                className={`dropdown-nav-link nav_link ${activeLink === '/about' ? 'active_link' : ''}`}
                            >
                                {selectedLanguage === 'en' ? 'About Us'
                                    : selectedLanguage === 'tr' ? 'Hakkımızda'
                                        : selectedLanguage === 'fa' ? 'درباره ما'
                                            : null
                                }

                                {isDropdownOpen ? (
                                    <FaAngleUp className='dropdown_icon'/>
                                ) : (
                                    <FaAngleDown className='dropdown_icon'/>
                                )}
                                
                            </Link>

                            {isDropdownOpen && (
                                <ul className="dropdown-content ">
                                    <Link
                                        to='/faq'
                                        onClick={() => {
                                            HandelClick();
                                            setActiveLink('/faq');
                                        }}
                                        className={`nav_link ${activeLink === '/faq' ? 'active_link' : ''}`}
                                    >
                                        {selectedLanguage === 'en' ? 'F&Q'
                                            : selectedLanguage === 'tr' ? 'SSS'
                                                : selectedLanguage === 'fa' ? 'سوالات متداول'
                                                    : null
                                        }
                                    </Link>

                                    <Link
                                        to='/testimonials'
                                        onClick={() => {
                                            HandelClick();
                                            setActiveLink('/testimonials');
                                        }}
                                        className={`nav_link ${activeLink === '/testimonials' ? 'active_link' : ''}`}
                                    >
                                        {selectedLanguage === 'en' ? 'Testimonials'
                                            : selectedLanguage === 'tr' ? 'Görüşler'
                                                : selectedLanguage === 'fa' ? 'نظرات مشتریان'
                                                    : null
                                        }
                                    </Link>
                                </ul>
                            )}
                        </li>

                        <li className="nav-item">
                            <Link
                                to='/contact'
                                onClick={() => {
                                    HandelClick();
                                    setActiveLink('/contact');
                                }}
                                className={`nav_link ${activeLink === '/contact' ? 'active_link' : ''}`}
                            >
                                {selectedLanguage === 'en' ? 'Contact'
                                    : selectedLanguage === 'tr' ? 'İletişim'
                                        : selectedLanguage === 'fa' ? 'تماس با ما'
                                            : null
                                }
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link
                                to='/consultation-form'
                                onClick={() => {
                                    HandelClick();
                                    setActiveLink('/consultation-form');
                                }}
                                className={`nav-button ${activeLink === '/consultation-form' ? 'active-nav-button' : ''}`}
                            >
                                {selectedLanguage === 'en' ? 'Request Consultation'
                                    : selectedLanguage === 'tr' ? 'Danışma Talebi'
                                        : selectedLanguage === 'fa' ? 'درخواست مشاوره'
                                            : null
                                }
                            </Link>
                        </li>
                    </ul>

                    <button className="nav_close" onClick={HandelClick}>
                        <IoClose/>
                    </button>
                </div>

                <div className="nav_buttons">

                    <Link
                        to='/consultation-form'
                        onClick={() => {
                            HandelClick();
                            setActiveLink('/consultation-form');
                        }}
                        className={`nav-button ${activeLink === '/consultation-form' ? 'active-nav-button' : ''}`}
                    >
                        {selectedLanguage === 'en' ? 'Request Consultation'
                            : selectedLanguage === 'tr' ? 'Danışma Talebi'
                                : selectedLanguage === 'fa' ? 'درخواست مشاوره'
                                    : null
                        }
                    </Link>

                    <button className='them_button' onClick={toggleTheme}>
                        {isButton ? (
                            <BsMoon className="change_theme"/>
                        ) : (
                            <FaRegSun className="change_theme"/>
                        )}
                    </button>

                    <div className="language-selector" onClick={toggleDropdown} ref={selectorRef}>
                        <div className="selected">
                            <img
                                src={selectedLanguageObj.flag}
                                className='language-selector-img'
                                alt={`${selectedLanguageObj.label} flag`}
                            />
                        </div>
                        {isOpen && (
                            <ul className="options">
                                {languages.map(lang => (
                                    <li key={lang.value} onClick={() => handleSelect(lang.value)}>
                                        <img
                                            src={lang.flag}
                                            className='language-selector-img'
                                            alt={`${lang.label} flag`}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <button className="nav_toggle" onClick={showNavMenu}>
                        <FaBars/>
                    </button>
                </div>
            </nav>
        </header>
    );
};

export default Header;
