// src/InternationalSection.js
import React, {useEffect, useState} from 'react';
import './International Section.css'; 

import AfghanistanFlag from "../../assest/Home/Afghanistan-Flag.png";

const countries = [
    {
        name: {
            en: 'Afghanistan',
            tr: 'Afganistan',
            fa: 'افغانستان'
        },
        flag: AfghanistanFlag
    },
    {
        name: {
            en: 'Morocco',
            tr: 'Fas',
            fa: 'مراکش'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg'
    },
    {
        name: {
            en: 'Turkey',
            tr: 'Türkiye',
            fa: 'ترکیه'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg'
    },
    {
        name: {
            en: 'Cyprus',
            tr: 'Kıbrıs',
            fa: 'قبرس'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg'
    },
    {
        name: {
            en: 'Australia',
            tr: 'Avustralya',
            fa: 'استرالیا'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Australia.svg'
    },
    {
        name: {
            en: 'United States',
            tr: 'Amerika Birleşik Devletleri',
            fa: 'ایالات متحده'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
    },
    {
        name: {
            en: 'Switzerland',
            tr: 'İsviçre',
            fa: 'سوئیس'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg'
    },
    {
        name: {
            en: 'Thailand',
            tr: 'Tayland',
            fa: 'تایلند'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg'
    },
    {
        name: {
            en: 'Germany',
            tr: 'Almanya',
            fa: 'آلمان'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
    },
    {
        name: {
            en: 'Sweden',
            tr: 'İsveç',
            fa: 'سوئد'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg'
    },
    {
        name: {
            en: 'Ukraine',
            tr: 'Ukrayna',
            fa: 'اوکراین'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg'
    },
    {
        name: {
            en: 'Hong Kong',
            tr: 'Hong Kong',
            fa: 'هنگ کنگ'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg'
    },
    {
        name: {
            en: 'United Kingdom',
            tr: 'Birleşik Krallık',
            fa: 'بریتانیا'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg'
    },
    {
        name: {
            en: 'Canada',
            tr: 'Kanada',
            fa: 'کانادا'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Canada.svg'
    },
    {
        name: {
            en: 'Mexico',
            tr: 'Meksika',
            fa: 'مکزیک'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg'
    },
    {
        name: {
            en: 'Indonesia',
            tr: 'Endonezya',
            fa: 'اندونزی'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg'
    },
    {
        name: {
            en: 'Malaysia',
            tr: 'Malezya',
            fa: 'مالزی'
        },
        flag: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg'
    },
];

const InternationalSection = ({selectedLanguage}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="international-section section" lang={selectedLanguage === 'fa' ? 'fa' : 'en'} >
            <div className='international-container container grid'>
                
                <h2 className='section_title-2 '>
                    {selectedLanguage === 'en' ? 'We work '
                        : selectedLanguage === 'tr' ? 'Uluslararası '
                            : selectedLanguage === 'fa' ? 'ما به صورت بین‌المللی '
                                : null
                    }
                     {isMobile && <br/>}
                    {selectedLanguage === 'en' ? 'internationally'
                        : selectedLanguage === 'tr' ? 'çalışıyoruz'
                            : selectedLanguage === 'fa' ? 'کار می‌کنیم.'
                                : null
                    }
                </h2>
                
                <p className='international-description'>
                    {selectedLanguage === 'en' ? 'We have a global team and a global list of clients, no matter what country you are located, we can work with you.'
                        : selectedLanguage === 'tr' ? 'Dünya çapında bir ekibimiz ve global bir müşteri listemiz var, hangi ülkede olursanız olun sizinle çalışabiliriz.'
                            : selectedLanguage === 'fa' ? 'ما یک تیم جهانی و یک فهرست جهانی از مشتریان داریم، مهم نیست که در کدام کشور قرار دارید، ما می‌توانیم با شما کار کنیم.'
                                : null
                    }
                </p>
                <div className="country-grid">
                    {countries.map((country, index) => (
                        <div key={index} className="country-item">
                            <img src={country.flag} alt={`${country.name} flag`}/>
                            <p className='country-name'>{country.name[selectedLanguage]}</p>
                        </div>
                    ))}
                </div>
                <p className="international-footer-text">
                    {selectedLanguage === 'en' ? 'And many more... Contact us, no matter your location.'
                        : selectedLanguage === 'tr' ? 'Ve daha fazlası... Nerede olursanız olun bizimle iletişime geçin.'
                            : selectedLanguage === 'fa' ? 'و بسیاری موارد دیگر... با ما تماس بگیرید، مهم نیست کجا هستید.'
                                : null
                    }
                </p>
            </div>
        </div>
    );
}

export default InternationalSection;
