import React, { useState, useEffect } from 'react';
import './Services.css';
import { MdBackupTable } from "react-icons/md";
import { Link } from "react-router-dom";

// images
import ServiceVectorOne from '../../assest/services/vector-1.png';
import ServiceVectorTwo from '../../assest/services/vector-2.png';
import ServiceVectorThree from '../../assest/services/vector-3.png';
import ServiceVectorFour from '../../assest/services/vector-4.png';
import ServiceVectorFive from '../../assest/services/vector-5.png';
import ServiceVectorSix from '../../assest/services/vector-6.png';
import ServiceVectorSeven from '../../assest/services/vector-7.png';

function Services({scrollToTop, selectedLanguage}) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(false); // Setting loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <section className="services_section" lang={selectedLanguage === 'fa' ? 'fa' : 'en'}  >
                <h2 className="section_title-2 service_title">
                    <span>
                        {selectedLanguage === 'en' ? 'Services'
                            : selectedLanguage === 'tr' ? 'Hizmetler'
                                : selectedLanguage === 'fa' ? 'خدمات'
                                    : null
                        }
                    </span>
                </h2>

                <div className="services_container container ">
                    {loading ? (
                        <div className='row_loader-container'>
                            <span className='loader'></span>
                        </div>
                    ) : (
                        <div className="services_container container grid" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                            <article className="services_card">
                                <div className="services_border"></div>
                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'Custom Web Design'
                                            : selectedLanguage === 'tr' ? 'Özel Web Tasarımı'
                                                : selectedLanguage === 'fa' ? 'طراحی وب سفارشی'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>
                                        {selectedLanguage === 'en' ? 'Our custom web design service ensures that your website reflects your brand identity and stands out from the competition. We create bespoke designs that are visually appealing, intuitive, and optimized for user experience.'
                                            : selectedLanguage === 'tr' ? 'Özel web tasarım hizmetimiz, web sitenizin marka kimliğinizi yansıtmasını ve rakiplerinizden sıyrılmasını sağlar. Görsel olarak çekici, sezgisel ve kullanıcı deneyimi için optimize edilmiş özel tasarımlar oluşturuyoruz.'
                                                : selectedLanguage === 'fa' ? 'خدمات طراحی وب سفارشی ما اطمینان می‌دهد که وب‌سایت شما هویت برند شما را منعکس کرده و از رقبا متمایز باشد. ما طراحی‌های سفارشی ایجاد می‌کنیم که از نظر بصری جذاب، شهودی و بهینه‌سازی شده برای تجربه کاربری هستند.'
                                                    : null
                                        }
                                    </p>
                                </div>
                                <img src={ServiceVectorOne} alt='picture-service' className='services-vector'/>
                            </article>

                            <article className="services_card">

                                <div className="services_border"></div>

                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'UX/UI Design'
                                            : selectedLanguage === 'tr' ? 'UX/UI Tasarımı'
                                                : selectedLanguage === 'fa' ? 'طراحی UX/UI'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>
                                        
                                        {selectedLanguage === 'en' ? 'User experience and interface design are at the heart of our web design process. We focus on creating interfaces that are not only beautiful but also easy to navigate, ensuring your visitors have a seamless experience on your site.'
                                            : selectedLanguage === 'tr' ? 'Kullanıcı deneyimi ve arayüz tasarımı web tasarım sürecimizin merkezinde yer alır. Sadece güzel değil aynı zamanda gezinmesi kolay arayüzler oluşturmaya odaklanıyoruz ve ziyaretçilerinizin sitenizde kusursuz bir deneyim yaşamasını sağlıyoruz.'
                                                : selectedLanguage === 'fa' ? 'تجربه کاربری و طراحی رابط در مرکز پروسه طراحی وب ما قرار دارد. ما بر روی ایجاد رابط‌هایی تمرکز می‌کنیم که نه تنها زیبا باشند بلکه به راحتی قابل دسترسی نیز باشند، تا اطمینان حاصل کنیم که بازدیدکنندگان شما تجربه‌ای بی‌نقص در وب‌سایت شما دارند.'
                                                    : null
                                        }
                                    </p>
                                </div>

                                <img src={ServiceVectorTwo}
                                     alt='picture-service'
                                     className='services-vector image-two'
                                />
                            </article>


                            <article className="services_card">
                                <div className="services_border"></div>
                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'Branding and Identity'
                                            : selectedLanguage === 'tr' ? 'Markalaşma ve Kimlik'
                                                : selectedLanguage === 'fa' ? 'برند و هویت'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>
                                        {selectedLanguage === 'en' ? 'We help you establish a strong online presence with cohesive branding that includes logo design, color schemes, typography, and more. Our designs reflect your brand\'s personality and values, creating a lasting impression on your audience.'
                                            : selectedLanguage === 'tr' ? 'Logo tasarımı, renk şemaları, tipografi ve daha fazlasını içeren tutarlı markalama ile güçlü bir çevrimiçi varlık oluşturmanıza yardımcı oluyoruz. Tasarımlarımız markanızın kişiliğini ve değerlerini yansıtarak hedef kitlenizde kalıcı bir izlenim yaratır.'
                                                : selectedLanguage === 'fa' ? 'ما به شما کمک می‌کنیم تا یک حضور قوی آنلاین با برندینگ هماهنگ که شامل طراحی لوگو، طرح‌های رنگی، تایپوگرافی و موارد دیگر است، برقرار کنید. طراحی‌های ما شخصیت و ارزش‌های برند شما را منعکس می‌کند و تأثیر ماندگاری بر روی مخاطبان شما ایجاد می‌کند.'
                                                    : null
                                        }
                                    </p>
                                </div>
                                <img src={ServiceVectorThree}
                                     alt='picture-service'
                                     className='services-vector image-two'
                                />

                            </article>


                            <article className="services_card">
                                <div className="services_border"></div>
                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'E-COMMERCE'
                                            : selectedLanguage === 'tr' ? 'E-TİCARET'
                                                : selectedLanguage === 'fa' ? 'تجارت الکترونیکی'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>
                                        {selectedLanguage === 'en' ? 'We create powerful e-commerce platforms that provide seamless shopping experiences for your customers. Our solutions include custom shopping cart development, payment gateway integration, and inventory management systems.'
                                            : selectedLanguage === 'tr' ? 'Müşterileriniz için kusursuz alışveriş deneyimleri sağlayan güçlü e-ticaret platformları yaratıyoruz. Çözümlerimiz arasında özel alışveriş sepeti geliştirme, ödeme ağ geçidi entegrasyonu ve envanter yönetim sistemleri yer alıyor.'
                                                : selectedLanguage === 'fa' ? 'ما پلتفرم‌های قدرتمند تجارت الکترونیک ایجاد می‌کنیم که تجربیات خرید بی‌نقصی را برای مشتریان شما فراهم می‌آورند. راه‌حل‌های ما شامل توسعه سبد خرید سفارشی، ادغام درگاه‌های پرداخت و سیستم‌های مدیریت موجودی است.'
                                                    : null
                                        }
                                    </p>
                                </div>
                                <img src={ServiceVectorFour}
                                     alt='picture-service'
                                     className='services-vector image-two'
                                />
                            </article>

                            <article className="services_card">
                                <div className="services_border"></div>
                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'Search Engine Optimization (SEO)'
                                            : selectedLanguage === 'tr' ? 'Arama Motoru Optimizasyonu (SEO)'
                                                : selectedLanguage === 'fa' ? 'بهینه‌سازی موتور جستجو (SEO)'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>

                                        {selectedLanguage === 'en' ? 'We improve your website’s ranking on search engines with our expert SEO services. Conducting thorough keyword research, optimizing your on-page content, building high-quality backlinks, and ensuring your site is technically sound, we achieve the best possible search engine results.'
                                            : selectedLanguage === 'tr' ? 'Uzman SEO hizmetlerimizle web sitenizin arama motorlarındaki sıralamasını iyileştiriyoruz. Kapsamlı anahtar kelime araştırması yaparak, sayfa içi içeriğinizi optimize ederek, yüksek kaliteli geri bağlantılar oluşturarak ve sitenizin teknik olarak sağlam olduğundan emin olarak mümkün olan en iyi arama motoru sonuçlarını elde ediyoruz.'
                                                : selectedLanguage === 'fa' ? 'ما با خدمات تخصصی سئو، رده‌بندی وب‌سایت شما را در موتورهای جستجو بهبود می‌بخشیم. با انجام تحقیقات دقیق کلمات کلیدی، بهینه‌سازی محتوای صفحه شما، ساخت بک‌لینک‌های باکیفیت و اطمینان از سلامت فنی سایت شما، بهترین نتایج ممکن را در موتورهای جستجو به دست می‌آوریم.'
                                                    : null
                                        }
                                    </p>
                                </div>

                                <img src={ServiceVectorFive}
                                     alt='picture-service'
                                     className='services-vector image-two'
                                />
                            </article>


                            <article className="services_card">
                                <div className="services_border"></div>
                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'Content Management Systems (CMS)'
                                            : selectedLanguage === 'tr' ? 'İçerik Yönetim Sistemleri (CMS)'
                                                : selectedLanguage === 'fa' ? 'سیستم‌های مدیریت محتوا (CMS)'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>
                                        {selectedLanguage === 'en' ? 'We develop and implement robust content management systems that allow you to easily update and manage your website content. Our CMS solutions are tailored to fit your specific needs and ensure your site remains dynamic and up-to-date.'
                                            : selectedLanguage === 'tr' ? 'Web sitenizin içeriğini kolayca güncellemenize ve yönetmenize olanak tanıyan sağlam içerik yönetim sistemleri geliştiriyor ve uyguluyoruz. CMS çözümlerimiz, özel ihtiyaçlarınıza uyacak şekilde tasarlanmıştır ve sitenizin dinamik ve güncel kalmasını sağlar.'
                                                : selectedLanguage === 'fa' ? 'ما سیستم‌های مدیریت محتوای قوی را توسعه و اجرا می‌کنیم که به شما این امکان را می‌دهد تا به راحتی محتوای وب‌سایت خود را به‌روزرسانی و مدیریت کنید. راه‌حل‌های CMS ما به‌گونه‌ای طراحی شده‌اند که با نیازهای خاص شما مطابقت داشته باشند و اطمینان حاصل کنند که وب‌سایت شما پویا و به‌روز باقی بماند.'
                                                    : null
                                        }
                                    </p>
                                </div>
                                <img src={ServiceVectorSix}
                                     alt='picture-service'
                                     className='services-vector image-two'
                                />
                            </article>


                            <article className="services_card">
                                <div className="services_border"></div>
                                <div className="services_content">
                                    <div>
                                        <div className="services_icon">
                                            <div className="services_box"></div>
                                            <MdBackupTable className='services_icons'/>
                                        </div>
                                    </div>
                                    <h2 className="services_title">
                                        {selectedLanguage === 'en' ? 'Website Maintenance and Support'
                                            : selectedLanguage === 'tr' ? 'Web Sitesi Bakımı ve Desteği'
                                                : selectedLanguage === 'fa' ? 'نگهداری و پشتیبانی وبسایت'
                                                    : null
                                        }
                                    </h2>
                                    <p className='service-description'>
                                        {selectedLanguage === 'en' ? 'We provide ongoing website maintenance and support services to ensure your website remains secure, functional, and up-to-date. Our team is always on hand to assist with updates, troubleshooting, and performance optimization.'
                                            : selectedLanguage === 'tr' ? 'Web sitenizin güvenli, işlevsel ve güncel kalmasını sağlamak için sürekli web sitesi bakımı ve destek hizmetleri sağlıyoruz. Ekibimiz güncellemeler, sorun giderme ve performans optimizasyonu konusunda yardımcı olmak için her zaman hazırdır.'
                                                : selectedLanguage === 'fa' ? 'ما خدمات نگهداری و پشتیبانی مداوم وب‌سایت را ارائه می‌دهیم تا اطمینان حاصل شود که وب‌سایت شما امن، کاربردی و به‌روز باقی بماند. تیم ما همیشه در دسترس است تا در زمینه به‌روزرسانی‌ها، عیب‌یابی و بهینه‌سازی عملکرد کمک کند.'
                                                    : null
                                        }
                                    </p>
                                </div>

                                <img src={ServiceVectorSeven}
                                     alt='picture-service'
                                     className='services-vector image-two'
                                />
                            </article>

                            <Link to='/projects' className='service-button' >
                                <button className="btn service-button" onClick={scrollToTop}>
                                    {selectedLanguage === 'en' ? 'Explore'
                                        : selectedLanguage === 'tr' ? 'Keşfetmek'
                                            : selectedLanguage === 'fa' ? 'اکتشاف کنید'
                                                : null
                                    }
                                </button>
                            </Link>

                        </div>


                    )}
                </div>


            </section>
        </>
    );
}

export default Services;

