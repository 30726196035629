import React, {useEffect, useRef, useState} from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from "./Component/Header/Header";
import Home from "./Component/Home/Home";
import About from "./Component/About/About";
import Services from "./Component/Services/Services";
import Projects from "./Component/Projects/Projects";
import Contact from "./Component/Contact/Contact";
import Footer from "./Component/Footer/Footer";
import { FaChevronUp } from "react-icons/fa";
import Skill from "./Component/My Skill/Skill";
import RecentProjects from "./Component/Recent Projects/Recent Projects";
import FAQ from "./Component/FAQ/FAQ";
import FourOFour from "./Component/404/Four O Four";
import ProjectDetails from "./Component/Project Details/Project Details";
import FloatingDiv from "./Component/FloatingDiv/FloatingDiv";
import Morocco from "./Component/Morocco/Morocco";
import InternationalSection from "./Component/International Section/International Section";
import ProcessSteps from "./Component/Process Step/ProcessSteps";
import ConsultationForm from "./Component/Consultation Form/Consultation Form ";
import TermCondition from "./Component/Terms and Condition/Term&Condition";
import CookiesPolicy from "./Component/Cookies Policy/CookiesPolicy";
import PrivacyPolicy from "./Component/Privacy Policy/PrivacyPolicy";
import ScrollToTop from "./utils/ScrollToTop";
import Breadcrumbs from "./Component/Breadcrumbs/Breadcrumbs";
import Testimonial from "./Component/Testimonials/Testimonials";

function App() {
    const [selectedProject, setSelectedProject] = useState(null);

    const viewsSelectedProject = (project) => {
        setSelectedProject(project);
    };

    const closeSelectedProject = () => {
        setSelectedProject(null);
    };

    const scrollUp = () => {
        const scrollUpElement = document.getElementById("scroll_up");
        // WHEN THE SCROLL IS HIGHER THAN 350 VIEWPORT HEIGHT, ADD the  TAG WITH THE SCROLL_UP CLASS
        window.scrollY >= 350
            ? scrollUpElement.classList.add("show_scroll")
            : scrollUpElement.classList.remove("show_scroll");
    };

    const selectedProjectRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectedProjectRef.current && !selectedProjectRef.current.contains(event.target)) {
                closeSelectedProject();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        window.addEventListener("scroll", scrollUp);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", scrollUp);
        };
    }, []); // Empty dependency array to run the effect only once on mount


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: adds smooth scrolling animation
        });
    };

    // ----------------------------- Language
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    // Function to detect the user's device language
    const detectLanguage = () => {
        const userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes('fa') || userLang.includes('ir')) {
            return 'fa';
        } else if (userLang.includes('tr')) {
            return 'tr';
        } else {
            return 'en';
        }
    };

    useEffect(() => {
        // Set the language based on the device's language
        const initialLanguage = detectLanguage();
        setSelectedLanguage(initialLanguage);
    }, []);

    // Language change handler
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const Morocco = () => {
        useEffect(() => {
            window.location.replace('https://chaymae.netlify.app/'); // Replace with the desired website
        }, []);

        return null;
    };

    return (

    <Router>
        <ScrollToTop />
        <Header 
            scrollToTop={scrollToTop}
            selectedLanguage={selectedLanguage}
            handleLanguageChange={handleLanguageChange}
        />
        <Breadcrumbs
            selectedLanguage={selectedLanguage}
        />
        <Routes>

            <Route path='/' exct element={
                <>
                    <Home
                        selectedLanguage={selectedLanguage}
                        scrollToTop={scrollToTop}
                    />
                    <ProcessSteps
                        selectedLanguage={selectedLanguage}
                    />
                    <Skill
                        selectedLanguage={selectedLanguage}
                    />
                    <InternationalSection
                        selectedLanguage={selectedLanguage}
                    />
                    <RecentProjects
                        selectedLanguage={selectedLanguage}
                        scrollToTop={scrollToTop}
                    />
                </>
            }/>

            <Route path='/about' element={
                <>
                    <About
                        selectedLanguage={selectedLanguage}
                    />
                    <Skill
                        selectedLanguage={selectedLanguage}
                    />
                </>
            }/>

            <Route path='/faq' element={
                <FAQ
                    selectedLanguage={selectedLanguage}
                />
            }/>

            <Route path='/testimonials' element={
                <Testimonial
                    selectedLanguage={selectedLanguage}
                />
            }
            />

            <Route path='/services' element={
                <>
                    <Services
                        scrollToTop={scrollToTop}
                        selectedLanguage={selectedLanguage}
                    />
                </>
            }/>

            <Route path='/projects'
                   element={
                <Projects
                       scrollToTop={scrollToTop}
                       selectedProject={selectedProject}
                       setSelectedProject={setSelectedProject}
                       selectedLanguage={selectedLanguage}
                />
            }/>

            <Route path='/Contact' element={
                <Contact                         
                    selectedLanguage={selectedLanguage}
            />}/>
            <Route path='/fourOfour' element={<FourOFour /> } />

            <Route path="/project_details/:id"
                    element={
                <ProjectDetails
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    viewsSlectedProject={viewsSelectedProject}
                    closeSelectedProject={closeSelectedProject}
                    selectedProjectRef={selectedProjectRef}
                    selectedLanguage={selectedLanguage}
                />
            }
            />

            <Route path="/consultation-form"
                   element={
                       <ConsultationForm
                           selectedLanguage={selectedLanguage}
                       />
                   }
            />

            <Route path='/terms-conditions' element={
                <TermCondition
                    selectedLanguage={selectedLanguage}
                />
            }
            />

            <Route path='/privacy-policy' element={
                <PrivacyPolicy
                    selectedLanguage={selectedLanguage}
                />
            }
            />

            <Route path='/cookies-privacy' element={
                <CookiesPolicy
                    selectedLanguage={selectedLanguage}
                />
            }
            />

            <Route path='/morocco' element={<Morocco/> } />
            
        </Routes>

        <Footer 
            scrollToTop={scrollToTop}
            selectedLanguage={selectedLanguage
        }
        />
        <button onClick={scrollToTop} className="scroll_up" id="scroll_up"><FaChevronUp className='icoons'/></button>

    </Router>
);
}

export default App;




