import React, { useState } from 'react';
import './FAQ.css';
import { FaStarOfLife } from 'react-icons/fa';

function FAQ({selectedLanguage}) {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} >
            <section className='faq_section' dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                <h2 className='faq-title section_title-2 '>
                    {selectedLanguage === 'en' ? 'Get All Your Answers Here'
                        : selectedLanguage === 'tr' ? <>Tüm Cevaplarınızı <br/> Buradan Alın </>
                            : selectedLanguage === 'fa' ? <>همه پاسخ های خود را<br/> از اینجا دریافت کنید</>
                                : null
                    }
                </h2>

                <div className='faq_container container grid'>
                    <div >
                        <h2 className='accordion_title'>
                            <FaStarOfLife className='accordion_title-icon' />
                            &nbsp;
                            {selectedLanguage === 'en' ? 'General Questions'
                                : selectedLanguage === 'tr' ? 'Genel Sorular'
                                    : selectedLanguage === 'fa' ? 'سوالات عمومی'
                                        : null
                            }
                        </h2>
                        {questionList.map((section, index) => (
                            <div key={index} className='accordion_content' >
                                <button
                                    className={`accordion ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => handleAccordionClick(index)}
                                    lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                >
                                    {section.title[selectedLanguage]}
                                </button>
                                <div
                                    className='panel'
                                    style={{
                                        maxHeight: activeIndex === index ? '500px' : '0',
                                        transition: 'max-height 0.3s ease-out',
                                    }}
                                >
                                    <p className='panel_text'>{section.content[selectedLanguage]}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default FAQ;


const questionList = [
    {
        title: {
            en: 'How long does it take you to design a website?',
            tr: 'Bir web sitesi tasarlamanız ne kadar sürer?',
            fa: 'طراحی یک وب سایت چقدر طول می‌کشد؟'
        },
        content: {
            en: "The timeline for website design varies based on the project's complexity and scope. Typically, it can take anywhere from a few weeks to a few months. I prioritize quality and ensure that the final product aligns with your vision and goals.",
            tr: "Bir web sitesi tasarımının süresi projenin karmaşıklığına ve kapsamına bağlı olarak değişir. Genellikle birkaç haftadan birkaç aya kadar sürebilir. Kaliteyi ön planda tutar ve nihai ürünün vizyonunuza ve hedeflerinize uygun olmasını sağlarım.",
            fa: "مدت زمان طراحی وب سایت بسته به پیچیدگی و گستره پروژه متفاوت است. معمولاً می‌تواند از چند هفته تا چند ماه طول بکشد. من کیفیت را در اولویت قرار می‌دهم و اطمینان حاصل می‌کنم که محصول نهایی با دیدگاه و اهداف شما همخوانی داشته باشد."
        },
    },
    {
        title: {
            en: 'What type of projects do you take on?',
            tr: 'Ne tür projeler üstleniyorsunuz?',
            fa: 'چه نوع پروژه‌هایی را می‌پذیرید؟'
        },
        content: {
            en: "I specialize in a wide range of projects, including corporate websites, e-commerce platforms, portfolio sites, and custom web applications. My expertise allows me to adapt to diverse industries and client needs.",
            tr: "Kurumsal web siteleri, e-ticaret platformları, portföy siteleri ve özel web uygulamaları da dahil olmak üzere geniş bir yelpazede projelerde uzmanım. Uzmanlığım, farklı endüstrilere ve müşteri ihtiyaçlarına uyum sağlamama olanak tanır.",
            fa: "من در طیف وسیعی از پروژه‌ها تخصص دارم، از جمله وب‌سایت‌های شرکتی، پلتفرم‌های تجارت الکترونیک، سایت‌های نمونه کار و برنامه‌های وب سفارشی. تخصص من به من این امکان را می‌دهد که با صنایع و نیازهای مختلف مشتریان سازگار شوم."
        },
    },
    {
        title: {
            en: 'How much do your services cost?',
            tr: 'Hizmetlerinizin maliyeti nedir?',
            fa: 'هزینه خدمات شما چقدر است؟'
        },
        content: {
            en: "Our pricing varies depending on the scope and complexity of the project. We offer customized quotes after an initial consultation to understand your specific needs.",
            tr: "Fiyatlarımız, projenin kapsamına ve karmaşıklığına bağlı olarak değişir. Spesifik ihtiyaçlarınızı anlamak için ilk danışmanın ardından özelleştirilmiş teklifler sunuyoruz.",
            fa: "قیمت‌های ما بسته به گستره و پیچیدگی پروژه متفاوت است. ما پس از مشاوره اولیه برای درک نیازهای خاص شما، قیمت‌های سفارشی ارائه می‌دهیم."
        },
    },
    {
        title: {
            en: 'How long does it take to build a website?',
            tr: 'Bir web sitesi oluşturmak ne kadar sürer?',
            fa: 'ساخت یک وب سایت چقدر طول می‌کشد؟'
        },
        content: {
            en: "Building a website can take anywhere from a few weeks to several months depending on the complexity, features required, and client collaboration. A clear timeline is usually established after discussing the project scope.",
            tr: "Bir web sitesi oluşturmak, karmaşıklık, gereken özellikler ve müşteri işbirliğine bağlı olarak birkaç hafta ile birkaç ay arasında sürebilir. Genellikle proje kapsamı tartışıldıktan sonra net bir zaman çizelgesi belirlenir.",
            fa: "ساخت یک وب سایت می‌تواند بسته به پیچیدگی، ویژگی‌های مورد نیاز و همکاری مشتری، از چند هفته تا چند ماه طول بکشد. معمولاً پس از بحث در مورد گستره پروژه، یک جدول زمانی واضح تعیین می‌شود."
        },
    },
    {
        title: {
            en: 'Do you provide ongoing support and maintenance?',
            tr: 'Sürekli destek ve bakım sağlıyor musunuz?',
            fa: 'آیا پشتیبانی و نگهداری مداوم ارائه می‌دهید؟'
        },
        content: {
            en: "Yes, we offer various maintenance packages to ensure your website remains up-to-date, secure, and performing optimally.",
            tr: "Evet, web sitenizin güncel, güvenli ve optimum performansta kalmasını sağlamak için çeşitli bakım paketleri sunuyoruz.",
            fa: "بله، ما بسته‌های نگهداری مختلفی را ارائه می‌دهیم تا اطمینان حاصل کنیم که وب‌سایت شما به‌روز، امن و بهینه عمل می‌کند."
        },
    },
    {
        title: {
            en: 'How often can we communicate?',
            tr: 'Ne sıklıkla iletişim kurabiliriz?',
            fa: 'چند وقت یکبار می‌توانیم ارتباط برقرار کنیم؟'
        },
        content: {
            en: "Communication is crucial for project success. I typically schedule regular check-ins, whether through email, video calls, or project management tools. This ensures that you are updated on the progress, and we can address any concerns promptly.",
            tr: "İletişim, projenin başarısı için çok önemlidir. Genellikle e-posta, video görüşmeleri veya proje yönetim araçları aracılığıyla düzenli kontroller planlıyorum. Bu, ilerleme konusunda güncel kalmanızı sağlar ve endişelerinizi zamanında ele alabiliriz.",
            fa: "ارتباطات برای موفقیت پروژه حیاتی است. من معمولاً بررسی‌های منظم را برنامه‌ریزی می‌کنم، چه از طریق ایمیل، تماس‌های ویدیویی یا ابزارهای مدیریت پروژه. این تضمین می‌کند که شما در جریان پیشرفت هستید و ما می‌توانیم هرگونه نگرانی را به‌موقع برطرف کنیم."
        },
    },
    {
        title: {
            en: 'How do I get started with your web development services?',
            tr: 'Web geliştirme hizmetlerinizle nasıl başlayabilirim?',
            fa: 'چگونه می‌توانم با خدمات توسعه وب شما شروع کنم؟'
        },
        content: {
            en: "You can get started by contacting us through our website or email. We'll schedule a consultation to discuss your project requirements and goals.",
            tr: "Web sitemiz veya e-posta yoluyla bizimle iletişime geçerek başlayabilirsiniz. Proje gereksinimlerinizi ve hedeflerinizi tartışmak için bir danışma toplantısı planlayacağız.",
            fa: "می‌توانید با تماس با ما از طریق وب‌سایت یا ایمیل شروع کنید. ما یک مشاوره برنامه‌ریزی خواهیم کرد تا نیازها و اهداف پروژه شما را بررسی کنیم."
        },
    },
    {
        title: {
            en: 'Can you help with content creation?',
            tr: 'İçerik oluşturma konusunda yardımcı olabilir misiniz?',
            fa: 'آیا می‌توانید در ایجاد محتوا کمک کنید؟'
        },
        content: {
            en: "Yes, we have a team of skilled writers who can create high-quality, engaging content tailored to your target audience.",
            tr: "Evet, hedef kitlenize yönelik yüksek kaliteli ve ilgi çekici içerikler oluşturabilecek yetenekli yazarlarımız var.",
            fa: "بله، ما یک تیم از نویسندگان ماهر داریم که می‌توانند محتوای با کیفیت و جذابی را متناسب با مخاطبان هدف شما ایجاد کنند."
        },
    },
    {
        title: {
            en: 'Can you redesign an existing website?',
            tr: 'Mevcut bir web sitesini yeniden tasarlayabilir misiniz?',
            fa: 'آیا می‌توانید یک وب‌سایت موجود را بازطراحی کنید؟'
        },
        content: {
            en: "Yes, we offer website redesign services. We'll assess your current site, discuss your goals, and create a plan for an improved design and functionality.",
            tr: "Evet, web sitesi yeniden tasarım hizmetleri sunuyoruz. Mevcut sitenizi değerlendirecek, hedeflerinizi tartışacak ve geliştirilmiş bir tasarım ve işlevsellik için bir plan oluşturacağız.",
            fa: "بله، ما خدمات بازطراحی وب‌سایت را ارائه می‌دهیم. ما سایت فعلی شما را ارزیابی خواهیم کرد، اهداف شما را بررسی خواهیم کرد و یک برنامه برای بهبود طراحی و عملکرد ایجاد خواهیم کرد."
        },
    },
];
