import React from "react";

function CookiesPolicy({ selectedLanguage = "en", contactEmail = "info@nacode.co" }) {
    return (
        <div className="main">
            <section className="term-condition-section section">
                <div className='container term-condition-container'>
                    <h1 className="section_title-1 term-condition-title">
                        <span>
                            {selectedLanguage === 'en' ? 'Cookie Policy'
                                : selectedLanguage === 'tr' ? 'Çerez Politikası'
                                    : selectedLanguage === 'fa' ? 'سیاست کوکی'
                                        : null
                            }
                        </span>
                    </h1>
                </div>

                <div className="tc-contents container">
                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Introduction'
                                    : selectedLanguage === 'tr' ? 'Giriş'
                                        : selectedLanguage === 'fa' ? 'معرفی'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? <>Welcome to <strong>Nacode</strong>. This Cookie Policy explains what cookies are, how we use them, and your choices regarding their use.</>
                                    : selectedLanguage === 'tr' ? <><strong>Nacode</strong>'ye hoş geldiniz. Bu Çerez Politikası, çerezlerin ne olduğunu, nasıl kullandığımızı ve bunların kullanımıyla ilgili seçeneklerinizi açıklar.</>
                                        : selectedLanguage === 'fa' ? <>به <strong>Nacode</strong> خوش آمدید. این سیاست کوکی توضیح می‌دهد که کوکی‌ها چیستند، چگونه از آنها استفاده می‌کنیم، و انتخاب‌های شما در مورد استفاده از آنها چیست.</>
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'What Are Cookies?'
                                    : selectedLanguage === 'tr' ? 'Çerezler Nedir?'
                                        : selectedLanguage === 'fa' ? 'کوکی‌ها چیستند؟'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'Cookies are small text files stored on your device (computer, tablet, or mobile) when you visit a website. They help the website remember your actions and preferences (such as login, language, font size, and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.'
                                    : selectedLanguage === 'tr' ? 'Çerezler, bir web sitesini ziyaret ettiğinizde cihazınıza (bilgisayar, tablet veya mobil) kaydedilen küçük metin dosyalarıdır. Web sitesinin eylemlerinizi ve tercihlerinizi (örneğin giriş yapma, dil, yazı tipi boyutu ve diğer görüntüleme tercihleri) bir süre boyunca hatırlamasına yardımcı olurlar, böylece siteye geri döndüğünüzde veya bir sayfadan diğerine göz atarken bunları tekrar girmek zorunda kalmazsınız.'
                                        : selectedLanguage === 'fa' ? 'کوکی‌ها فایل‌های متنی کوچکی هستند که هنگام بازدید از یک وب‌سایت بر روی دستگاه شما (کامپیوتر، تبلت یا موبایل) ذخیره می‌شوند. آنها به وب‌سایت کمک می‌کنند تا اقدامات و ترجیحات شما (مانند ورود به سیستم، زبان، اندازه قلم و سایر تنظیمات نمایش) را برای مدت زمان مشخصی به خاطر بسپارد، بنابراین هر بار که به سایت بازمی‌گردید یا از یک صفحه به صفحه دیگر مرور می‌کنید، نیازی به وارد کردن مجدد آنها نخواهید داشت.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'How We Use Cookies'
                                    : selectedLanguage === 'tr' ? 'Çerezleri Nasıl Kullanıyoruz'
                                        : selectedLanguage === 'fa' ? 'چگونه از کوکی‌ها استفاده می‌کنیم'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'We use cookies to:'
                                    : selectedLanguage === 'tr' ? 'Çerezleri şu amaçlarla kullanıyoruz:'
                                        : selectedLanguage === 'fa' ? 'ما از کوکی‌ها برای اهداف زیر استفاده می‌کنیم:'
                                            : null
                                }
                            </span>
                        </p>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Improve website functionality: Enhance your browsing experience by remembering your preferences and settings.'
                                        : selectedLanguage === 'tr' ? 'Web sitesi işlevselliğini geliştirin: Tercihlerinizi ve ayarlarınızı hatırlayarak gezinme deneyiminizi iyileştirin.'
                                            : selectedLanguage === 'fa' ? 'بهبود عملکرد وب‌سایت: تجربه مرور شما را با به خاطر سپردن تنظیمات و ترجیحاتتان بهبود می‌بخشیم.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Analyze usage: Understand how you interact with our website, helping us improve its structure, content, and user experience.'
                                        : selectedLanguage === 'tr' ? 'Kullanımı analiz edin: Web sitemizle nasıl etkileşimde bulunduğunuzu anlayarak, yapısını, içeriğini ve kullanıcı deneyimini iyileştirmemize yardımcı olun.'
                                            : selectedLanguage === 'fa' ? 'تجزیه و تحلیل استفاده: درک کنید که چگونه با وب‌سایت ما تعامل می‌کنید، که به ما در بهبود ساختار، محتوا و تجربه کاربر کمک می‌کند.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Marketing: Deliver relevant advertisements and measure their effectiveness.'
                                        : selectedLanguage === 'tr' ? 'Pazarlama: İlgili reklamlar sunun ve bunların etkinliğini ölçün.'
                                            : selectedLanguage === 'fa' ? 'بازاریابی: ارائه تبلیغات مرتبط و اندازه‌گیری اثربخشی آنها.'
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Types of Cookies We Use'
                                    : selectedLanguage === 'tr' ? 'Kullandığımız Çerez Türleri'
                                        : selectedLanguage === 'fa' ? 'انواع کوکی‌هایی که استفاده می‌کنیم'
                                            : null
                                }
                            </span>
                        </h2>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Essential Cookies: Necessary for the basic functionality of the website. Without these, some parts of our website may not work properly.'
                                        : selectedLanguage === 'tr' ? 'Gerekli Çerezler: Web sitesinin temel işlevselliği için gereklidir. Bunlar olmadan, web sitemizin bazı bölümleri düzgün çalışmayabilir.'
                                            : selectedLanguage === 'fa' ? 'کوکی‌های ضروری: برای عملکرد پایه وب‌سایت ضروری هستند. بدون این‌ها، برخی از قسمت‌های وب‌سایت ما ممکن است به درستی کار نکند.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Performance Cookies: Collect information about how you use our website, such as which pages you visit most often. These cookies do not collect personal information and are used to improve how our website works.'
                                        : selectedLanguage === 'tr' ? 'Performans Çerezleri: Web sitemizi nasıl kullandığınıza dair bilgiler toplar, örneğin en sık hangi sayfaları ziyaret ettiğiniz gibi. Bu çerezler kişisel bilgi toplamaz ve web sitemizin nasıl çalıştığını iyileştirmek için kullanılır.'
                                            : selectedLanguage === 'fa' ? 'کوکی‌های عملکرد: اطلاعاتی را در مورد نحوه استفاده شما از وب‌سایت ما جمع‌آوری می‌کنند، مانند صفحاتی که بیشتر بازدید می‌کنید. این کوکی‌ها اطلاعات شخصی جمع‌آوری نمی‌کنند و برای بهبود عملکرد وب‌سایت ما استفاده می‌شوند.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Functional Cookies: Allow our website to remember choices you make (such as your username, language, or region) and provide enhanced, more personal features.'
                                        : selectedLanguage === 'tr' ? 'İşlevsel Çerezler: Web sitemizin yaptığınız seçimleri (örneğin kullanıcı adınız, diliniz veya bölgeniz) hatırlamasına ve geliştirilmiş, daha kişisel özellikler sunmasına olanak tanır.'
                                            : selectedLanguage === 'fa' ? 'کوکی‌های عملکردی: به وب‌سایت ما اجازه می‌دهند انتخاب‌هایی که انجام می‌دهید (مانند نام کاربری، زبان یا منطقه) را به خاطر بسپارد و ویژگی‌های بهبود یافته و شخصی‌تر را ارائه دهد.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Advertising Cookies: Used to deliver ads more relevant to you and your interests. They also help limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.'
                                        : selectedLanguage === 'tr' ? 'Reklam Çerezleri: Size ve ilgi alanlarınıza daha uygun reklamlar sunmak için kullanılır. Ayrıca, bir reklamı kaç kez gördüğünüzü sınırlamaya yardımcı olur ve reklam kampanyalarının etkinliğini ölçer.'
                                            : selectedLanguage === 'fa' ? 'کوکی‌های تبلیغاتی: برای ارائه تبلیغات مرتبط‌تر با شما و علایقتان استفاده می‌شوند. آنها همچنین به محدود کردن تعداد دفعاتی که یک تبلیغ را می‌بینید کمک می‌کنند و اثربخشی کمپین‌های تبلیغاتی را اندازه‌گیری می‌کنند.'
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Your Choices Regarding Cookies'
                                    : selectedLanguage === 'tr' ? 'Çerezler Konusundaki Seçenekleriniz'
                                        : selectedLanguage === 'fa' ? 'انتخاب‌های شما در مورد کوکی‌ها'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'You have the option to control the use of cookies on our website. Here’s how:'
                                    : selectedLanguage === 'tr' ? 'Web sitemizde çerezlerin kullanımını kontrol etme seçeneğine sahipsiniz. İşte nasıl:'
                                        : selectedLanguage === 'fa' ? 'شما گزینه‌ای دارید که استفاده از کوکی‌ها را در وب‌سایت ما کنترل کنید. در اینجا نحوه انجام این کار آمده است:'
                                            : null
                                }
                            </span>
                        </p>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Browser Settings: Most web browsers allow you to control cookies through their settings preferences. You can set your browser to refuse cookies or delete certain cookies.'
                                        : selectedLanguage === 'tr' ? 'Tarayıcı Ayarları: Çoğu web tarayıcısı, ayar tercihleri aracılığıyla çerezleri kontrol etmenize olanak tanır. Tarayıcınızı çerezleri reddetmek veya belirli çerezleri silmek üzere ayarlayabilirsiniz.'
                                            : selectedLanguage === 'fa' ? 'تنظیمات مرورگر: اکثر مرورگرهای وب به شما امکان می‌دهند کوکی‌ها را از طریق تنظیماتشان کنترل کنید. می‌توانید مرورگر خود را برای رد کردن کوکی‌ها یا حذف برخی کوکی‌ها تنظیم کنید.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Opt-Out Links: Some third-party services we use may offer their own opt-out options. You can refer to their privacy policies for more information.'
                                        : selectedLanguage === 'tr' ? 'Çıkış Bağlantıları: Kullandığımız bazı üçüncü taraf hizmetler, kendi çıkış seçeneklerini sunabilir. Daha fazla bilgi için gizlilik politikalarına başvurabilirsiniz.'
                                            : selectedLanguage === 'fa' ? 'لینک‌های انصراف: برخی از خدمات شخص ثالثی که ما استفاده می‌کنیم ممکن است گزینه‌های انصراف خود را ارائه دهند. می‌توانید برای اطلاعات بیشتر به سیاست‌های حفظ حریم خصوصی آنها مراجعه کنید.'
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'Please note that if you disable or refuse cookies, some parts of our website may become inaccessible or not function properly.'
                                    : selectedLanguage === 'tr' ? 'Lütfen çerezleri devre dışı bırakırsanız veya reddederseniz, web sitemizin bazı bölümlerinin erişilemez hale gelebileceğini veya düzgün çalışmayabileceğini unutmayın.'
                                        : selectedLanguage === 'fa' ? 'لطفاً توجه داشته باشید که اگر کوکی‌ها را غیرفعال یا رد کنید، برخی از قسمت‌های وب‌سایت ما ممکن است غیرقابل دسترسی شوند یا به درستی کار نکنند.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Changes to This Cookie Policy'
                                    : selectedLanguage === 'tr' ? 'Bu Çerez Politikasındaki Değişiklikler'
                                        : selectedLanguage === 'fa' ? 'تغییرات در این سیاست کوکی'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'We may update our Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page.'
                                    : selectedLanguage === 'tr' ? 'Uygulamalarımızdaki değişiklikleri yansıtmak veya diğer operasyonel, yasal veya düzenleyici nedenlerle Çerez Politikamızı zaman zaman güncelleyebiliriz. Herhangi bir değişiklik bu sayfada yayınlanacaktır.'
                                        : selectedLanguage === 'fa' ? 'ما ممکن است سیاست کوکی خود را از زمان به زمان به‌روزرسانی کنیم تا تغییرات در شیوه‌های خود یا به دلایل دیگر عملیاتی، قانونی یا نظارتی را منعکس کنیم. هرگونه تغییر در این صفحه اعلام خواهد شد.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Contact Us'
                                    : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                        : selectedLanguage === 'fa' ? 'تماس با ما'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? `If you have any questions about this privacy policy, please contact us at ${contactEmail}.`
                                    : selectedLanguage === 'tr' ? `Bu gizlilik politikası hakkında herhangi bir sorunuz varsa, lütfen bizimle şu adresten iletişime geçin: ${contactEmail}.`
                                        : selectedLanguage === 'fa' ? `اگر در مورد این سیاست حفظ حریم خصوصی سوالی دارید، لطفاً از طریق ${contactEmail} با ما تماس بگیرید.`
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'This policy is effective as of 2024.'
                                    : selectedLanguage === 'tr' ? 'Bu politika 2024 yılı itibariyle geçerlidir.'
                                        : selectedLanguage === 'fa' ? 'این سیاست از سال 2024 موثر است.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                </div>
            </section>
        </div>
    )
}

export default CookiesPolicy;
