import React, { useRef, useState } from 'react';
import './Contact.css';
import curved_arrow from '../../assest/curved arrow.svg';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

import emailjs from '@emailjs/browser';


function Contact({ selectedLanguage }) {
    const [showMessage, setShowMessage] = useState(false);

    const handleShowMessage = () => {
        setShowMessage(true);

        // Hide the message after 3 seconds
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    };

    const form = useRef();

    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_subject: '',
        user_message: '',
    });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_mdi3b4a', 'template_92mrt3h', form.current, 'qoQqXCBRWR0-CiGfM')
            .then((result) => {
                console.log(result.text);
                setFormData({
                    user_name: '',
                    user_email: '',
                    user_subject: '',
                    user_message: '',
                });
                handleShowMessage();
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const darClass = selectedLanguage === 'fa' ? 'dari' : '';
    
    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="contact_section">
                <div className="contact_container grid">
                    <div className="contact_data">
                        <h2 className={`section_title-2 ${darClass}`} >
                            <span>
                                {selectedLanguage === 'en' ? 'Contact Us'
                                    : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                        : selectedLanguage === 'fa' ? 'با ما تماس بگیرید'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className="contact_description-1">
                            {selectedLanguage === 'en' ? 'Drop us a message. We will ensure a thorough review of all emails.'
                                : selectedLanguage === 'tr' ? 'Bize bir mesaj bırakın. Tüm e-postaları titizlikle inceleyeceğiz.'
                                    : selectedLanguage === 'fa' ? 'برای ما پیامی بگذارید. ما اطمینان خواهیم داد که تمامی ایمیل‌ها بررسی شوند.'
                                        : null
                            }
                        </p>

                        <p className="contact_description-2">
                            {selectedLanguage === 'en' ? 'We require your Full Name and a Contact Email Address. Rest assured, you\'ll only hear back from us based on your response.'
                                : selectedLanguage === 'tr' ? 'Adınız ve e-posta adresinizi belirtmeniz gerekmektedir. Yanıtınıza bağlı olarak sizlere geri dönüş yapacağız.'
                                    : selectedLanguage === 'fa' ? 'نام کامل و آدرس ایمیل خود را وارد کنید. مطمئن باشید فقط در صورت نیاز با شما تماس خواهیم گرفت.'
                                        : null
                            }
                        </p>

                        <div className="geometric_box"></div>
                    </div>

                    <div className="contact_mail">
                        <h2 className="contact_title">
                            {selectedLanguage === 'en' ? 'Send Us A Message'
                                : selectedLanguage === 'tr' ? 'Bize Bir Mesaj Gönderin'
                                    : selectedLanguage === 'fa' ? 'برای ما پیامی بفرستید'
                                        : null
                            }
                        </h2>

                        <form className="contact_form" ref={form} onSubmit={sendEmail}>
                            <div className="contact_group">
                                <div className="contact_box">
                                    <input 
                                        type="text" name="user_name" 
                                        className="contact_input" 
                                        value={formData.user_name}
                                        onChange={handleInputChange} 
                                        placeholder={
                                        selectedLanguage === 'en' ? 'First Name'
                                            : selectedLanguage === 'tr' ? 'Ad'
                                                : selectedLanguage === 'fa' ? 'نام'
                                                    : null} 
                                        required
                                        lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                    />
                                    <label htmlFor="user_name" className={`contact_label ${darClass}`} >
                                        {selectedLanguage === 'en' ? 'First Name'
                                            : selectedLanguage === 'tr' ? 'Ad'
                                                : selectedLanguage === 'fa' ? 'نام'
                                                    : null
                                        }
                                    </label>
                                </div>

                                <div className="contact_box">
                                    <input type="email" name="user_email" className="contact_input"
                                           value={formData.user_email} onChange={handleInputChange}
                                           placeholder={
                                               selectedLanguage === 'en' ? 'Email Address'
                                                   : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                                       : selectedLanguage === 'fa' ? 'آدرس ایمیل'
                                                           : null}
                                           lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                           required 
                                    />
                                    <label htmlFor="user_email" className={`contact_label ${darClass}`}>
                                        {selectedLanguage === 'en' ? 'Email Address'
                                            : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                                : selectedLanguage === 'fa' ? 'آدرس ایمیل'
                                                    : null
                                        }
                                    </label>
                                </div>
                            </div>

                            <div className="contact_box">
                                <input type="text" name="user_subject" className="contact_input" placeholder={
                                    selectedLanguage === 'en' ? 'Subject'
                                        : selectedLanguage === 'tr' ? 'Konu'
                                            : selectedLanguage === 'fa' ? 'موضوع'
                                                : null}
                                       lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                       value={formData.user_subject} onChange={handleInputChange} required 
                                />
                                <label htmlFor="user_subject" className={`contact_label ${darClass}`}>
                                    {selectedLanguage === 'en' ? 'Subject'
                                        : selectedLanguage === 'tr' ? 'Konu'
                                            : selectedLanguage === 'fa' ? 'موضوع'
                                                : null
                                    }
                                </label>
                            </div>

                            <div className="contact_box contact_area">
                                <textarea 
                                    className="contact_input" 
                                    name='user_message' 
                                    id="message" 
                                    required
                                    placeholder={
                                              selectedLanguage === 'en' ? 'Message'
                                                  : selectedLanguage === 'tr' ? 'Mesaj'
                                                      : selectedLanguage === 'fa' ? 'پیام'
                                                          : ''
                                          } 
                                    value={formData.user_message}
                                    lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                    onChange={handleInputChange}
                                >
                                </textarea>
                                <label htmlFor="user_message" className={`contact_label ${darClass}`}>
                                    {selectedLanguage === 'en' ? 'Message'
                                        : selectedLanguage === 'tr' ? 'Mesaj'
                                            : selectedLanguage === 'fa' ? 'پیام'
                                                : null
                                    }
                                </label>
                            </div>

                            <p className="contact_message" id="contact_message"></p>

                            <button 
                                className="contact_button button" 
                                type="submit"                                            
                                lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                            >
                                <FaPaperPlane />
                                {selectedLanguage === 'en' ? 'Send'
                                    : selectedLanguage === 'tr' ? 'Gönder'
                                        : selectedLanguage === 'fa' ? 'ارسال'
                                            : null
                                }
                            </button>

                            {showMessage &&
                                <div className="contact_popup">
                                    <p className="contact_popup-text">
                                        {selectedLanguage === 'en' ? 'Message sent!'
                                            : selectedLanguage === 'tr' ? 'Mesaj gönderildi!'
                                                : selectedLanguage === 'fa' ? 'پیام ارسال شد!'
                                                    : null
                                        } <TiTick className='contact_popup-icon' /></p>
                                </div>
                            }

                        </form>
                    </div>

                    <div className="contact_social">
                        <img src={curved_arrow} alt="image" className="contact_social-arrow" />

                        <div className="contact_social-data">
                            <div>
                                <p className="contact_social-description-1">
                                    {selectedLanguage === 'en' ? 'not send emails'
                                        : selectedLanguage === 'tr' ? 'e-posta göndermeyin'
                                            : selectedLanguage === 'fa' ? 'ایمیل نفرستید'
                                                : null
                                    }
                                </p>

                                <p className="contact_social-description-2">
                                    {selectedLanguage === 'en' ? "Let's get social."
                                        : selectedLanguage === 'tr' ? 'Sosyal medyada bizi takip edin.'
                                            : selectedLanguage === 'fa' ? 'با ما در شبکه‌های اجتماعی همراه شوید.'
                                                : null
                                    }
                                </p>
                            </div>
                            
                            <br/>

                            <div className="contact_social-links">
                                <a href="https://www.facebook.com/share/sq3BHocRxXnUFZoV/?mibextid=LQQJ4d" target="_blank" className="contact_social-link" rel="noreferrer">
                                    <FaFacebook />
                                </a>

                                <a href="https://www.instagram.com/nacode.webdev/?igsh=NTZyemRkcjdsem05&utm_source=qr" target="_blank" className="contact_social-link" rel="noreferrer">
                                    <FaInstagram />

                                </a>

                                <a href="https://www.linkedin.com/in/nasirullah-ahmadi-64785b294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" className="contact_social-link" rel="noreferrer">
                                    <FaLinkedin />
                                </a>
                            </div>

                        </div>
                    </div>

                </div>

            </section>

        </main>
    );
}

export default Contact;
