// src/ProcessSteps.js
import React from 'react';
import { FaSearch, FaPencilAlt, FaCode, FaRocket } from 'react-icons/fa';
import './ProcessSteps.css';

const ProcessSteps = ({selectedLanguage}) => {
    return (
        <div className="process-steps-container" lang={selectedLanguage === 'fa' ? 'fa' : 'en'} >
            <h2 className='section_title-1'>
                {selectedLanguage === 'en' ? 'A thoughtful process.'
                    : selectedLanguage === 'tr' ? 'Düşünülmüş bir süreç.'
                        : selectedLanguage === 'fa' ? 'یک پروسه‌ی اندیشمندانه.'
                            : null
                }
            </h2>
            <h6 className='process-steps-description'>
                {selectedLanguage === 'en' ? 'Our Strategy'
                    : selectedLanguage === 'tr' ? 'Stratejimiz'
                        : selectedLanguage === 'fa' ? 'استراتژی ما'
                            : null
                }
                <br/>
                {selectedLanguage === 'en' ? 'Simplicity and Precision'
                    : selectedLanguage === 'tr' ? 'Basitlik ve Kesinlik'
                        : selectedLanguage === 'fa' ? 'سادگی و دقت'
                            : null
                }
            </h6>

            <div className="process-steps" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                <div className="step">
                    <FaSearch className="step-icon" />
                    <h2>
                        {selectedLanguage === 'en' ? 'Discover'
                            : selectedLanguage === 'tr' ? 'Keşfetmek'
                                : selectedLanguage === 'fa' ? 'کشف کنید'
                                    : null
                        }
                    </h2>
                    <p className='step-description'>
                        {selectedLanguage === 'en' ? 'We initiate the process by attentively listening to your ideas, distilling them to identify the core objectives of your business.'
                            : selectedLanguage === 'tr' ? 'Fikirlerinizi dikkatle dinleyerek, bunları özümseyerek işletmenizin temel hedeflerini belirleyerek süreci başlatıyoruz.'
                                : selectedLanguage === 'fa' ? 'ما فرایند را با دقت به ایده‌های شما گوش دادن آغاز می‌کنیم و آن‌ها را تصفیه می‌کنیم تا اهداف اصلی کسب‌وکار شما را شناسایی کنیم.'
                                    : null
                        }
                    </p>
                </div>
                <div className="step">
                    <FaPencilAlt className="step-icon" />
                    <h2>
                        {selectedLanguage === 'en' ? 'Design'
                            : selectedLanguage === 'tr' ? 'Tasarım'
                                : selectedLanguage === 'fa' ? 'طراحی'
                                    : null
                        }
                    </h2>
                    <p className='step-description' >
                        {selectedLanguage === 'en' ? 'We then craft a comprehensive strategy designed to optimize the user\'s experience from start to finish.'
                            : selectedLanguage === 'tr' ? 'Daha sonra, kullanıcı deneyimini baştan sona optimize etmeyi amaçlayan kapsamlı bir strateji oluşturuyoruz.'
                                : selectedLanguage === 'fa' ? 'ما سپس یک استراتژی جامع طراحی می‌کنیم که برای بهینه‌سازی تجربه کاربر از ابتدا تا انتها طراحی شده است.'
                                    : null
                        }
                    </p>
                </div>
                <div className="step">
                    <FaCode className="step-icon" />
                    <h2>
                        {selectedLanguage === 'en' ? 'Develop'
                            : selectedLanguage === 'tr' ? 'Geliştirmek'
                                : selectedLanguage === 'fa' ? 'توسعه دادن'
                                    : null
                        }
                    </h2>
                    <p className='step-description'>
                        {selectedLanguage === 'en' ? 'Our development team collaborates closely with our creative experts to ensure precise and high-quality coding.'
                            : selectedLanguage === 'tr' ? 'Geliştirme ekibimiz, hassas ve yüksek kaliteli kodlamayı garantilemek için yaratıcı uzmanlarımızla yakın bir şekilde işbirliği yapmaktadır.'
                                : selectedLanguage === 'fa' ? 'تیم توسعه ما به طور نزدیک با کارشناسان خلاق ما همکاری می‌کند تا کدگذاری دقیق و با کیفیت بالا را تضمین کند.'
                                    : null
                        }
                    </p>
                </div>
                <div className="step">
                    <FaRocket className="step-icon" />
                    <h2>
                        {selectedLanguage === 'en' ? 'Deploy'
                            : selectedLanguage === 'tr' ? 'Dağıtmak'
                                : selectedLanguage === 'fa' ? 'مستقر کردن'
                                    : null
                        }
                    </h2>
                    <p className='step-description' >
                        {selectedLanguage === 'en' ? 'We believe in the art of execution. Partnering with you, we launch and continuously iterate, ensuring optimal performance and ongoing refinement.'
                            : selectedLanguage === 'tr' ? 'Uygulama sanatına inanıyoruz. Sizinle ortaklık kurarak, en iyi performansı ve devam eden iyileştirmeyi garanti ederek başlatır ve sürekli yineleriz.'
                                : selectedLanguage === 'fa' ? 'ما به هنر اجرا باور داریم. با همکاری شما، ما آغاز می‌کنیم و به طور مداوم بهبود می‌دهیم، تا عملکرد بهینه و اصلاح مداوم را تضمین کنیم.'
                                    : null
                        }
                        
                    </p>
                </div>
            </div>

        </div>
    );
};

export default ProcessSteps;
