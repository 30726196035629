import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillPicture } from "react-icons/ai";
import { FaFilm } from "react-icons/fa6";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import './Projects.css';
import { ProjectsList } from "../../Projects List";
import AlertModal from '../Alert Modal/AlertModal';

function Projects({ selectedLanguage }) {
    const [projectsList, ] = useState(ProjectsList);
    const [showFirstLogo, setShowFirstLogo] = useState(true); // State to track which logo to show

    useEffect(() => {
        const toggleLogo = () => {
            setShowFirstLogo((prevShowFirstLogo) => !prevShowFirstLogo);
        };

        const intervalId = setInterval(toggleLogo, 2000);

        return () => clearInterval(intervalId);
    }, []);

    const sortedProjectsList = [...projectsList].sort((a, b) => a.id - b.id);
    const combinedSortedLists = [...sortedProjectsList];

    return (
        <main className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'}>
            <section className="projects_section" id="projects" >

                <h2 className="section_title-1 project_title">
                    {selectedLanguage === 'en' ? 'Projects'
                        : selectedLanguage === 'tr' ? 'Projeler'
                            : selectedLanguage === 'fa' ? 'پروژه ها'
                                : null
                    }
                </h2>
                <br/>
                <div className="projects-container container grid" >
                    {
                        combinedSortedLists.map((item) => (
                            <ProjectCard
                                key={item.id}
                                item={item}
                                showFirstLogo={showFirstLogo}
                                selectedLanguage={selectedLanguage}
                            />
                        ))
                    }
                </div>
            </section>
        </main>
    );
}

export default Projects;

const ProjectCard = ({ item, showFirstLogo, selectedLanguage }) => {
    const [showAlert, setShowAlert] = useState(false);

    const handleLivePreviewClick = (e) => {
        if (!item.livePreview) {
            e.preventDefault();
            setShowAlert(true);
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (
        <article className="projects_card">
            <Link to={`/project_details/${item.id}`}>
                <div className="projects_image">
                    {Array.isArray(item.img) && item.img.length > 0 && (
                        <img src={item.img[0]} alt={item.title.en} className="projects_img"/>
                    )}
                    <button className="projects_button button">
                        {showFirstLogo ? (
                            <AiFillPicture className="p_icon"/>
                        ) : (
                            <FaFilm className="p_icon"/>
                        )}
                    </button>
                </div>
                <div className="projects_content">
                    <h3 className="projects_subtitle">
                        {selectedLanguage === 'en' ? item.sub.en
                            : selectedLanguage === 'tr' ? item.sub.tr
                                : selectedLanguage === 'fa' ? item.sub.fa
                                    : null
                        }
                    </h3>

                    <h2 className="projects_title" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                        {selectedLanguage === 'en' ? item.title.en
                            : selectedLanguage === 'tr' ? item.title.tr
                                : selectedLanguage === 'fa' ? item.title.fa
                                    : null
                        }
                    </h2>
                </div>
            </Link>
            <div className="projects_buttons">
            <a
                    href={item.livePreview || "#"}
                    className="project-live-link button "
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLivePreviewClick}
                >
                    <BsFillGrid1X2Fill className="github_icon"/> Live Preview
                </a>
            </div>
            {showAlert && <AlertModal message="Live preview is not available for this project." onClose={closeAlert} />}
        </article>
    );
};
