import React from "react";

function PrivacyPolicy({ selectedLanguage = "en", contactEmail = "info@nacode.co" }) {
    return (
        <div className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="term-condition-section section">
                <div className='container term-condition-container'>
                    <h1 className="section_title-1 term-condition-title">
                        <span>
                            {selectedLanguage === 'en' ? 'Privacy Policy'
                                : selectedLanguage === 'tr' ? 'Gizlilik Politikası'
                                    : selectedLanguage === 'fa' ? 'سیاست حفظ حریم خصوصی'
                                        : null
                            }
                        </span>
                    </h1>
                </div>

                <div className="tc-contents container">
                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Introduction'
                                    : selectedLanguage === 'tr' ? 'Giriş'
                                        : selectedLanguage === 'fa' ? 'معرفی'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? `Welcome to <strong>Nacode</strong>. Your privacy is important to us. This privacy policy explains how we collect, use, and protect your personal information when you visit our website <strong>www.nacode.co</strong>.`
                                    : selectedLanguage === 'tr' ? `Nacode'ye hoş geldiniz. Gizliliğiniz bizim için önemlidir. Bu gizlilik politikası, web sitemizi ziyaret ettiğinizde kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu açıklar .`
                                        : selectedLanguage === 'fa' ? <>به Nacode خوش آمدید. حفظ حریم خصوصی شما برای ما مهم است. این سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه هنگام بازدید از وب‌سایت ما اطلاعات شخصی شما را جمع‌آوری، استفاده و محافظت می‌کنیم <strong>www.nacode.co</strong>.</>
                                            : null
                                }

                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Information We Collect'
                                    : selectedLanguage === 'tr' ? 'Topladığımız Bilgiler'
                                        : selectedLanguage === 'fa' ? 'اطلاعاتی که جمع‌آوری می‌کنیم'
                                            : null
                                }
                            </span>
                        </h2>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? <><strong>Personal Information:</strong> Name, email address, phone number, and other contact details you provide.</>
                                        : selectedLanguage === 'tr' ? <><strong>Kişisel Bilgiler:</strong> Ad, e-posta adresi, telefon numarası ve sağladığınız diğer iletişim bilgileri.</>
                                            : selectedLanguage === 'fa' ? <><strong>اطلاعات شخصی:</strong> نام، آدرس ایمیل، شماره تلفن و سایر جزئیات تماس که شما ارائه می‌دهید.</>
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? <><strong>Usage Data:</strong> Information on how you use our website, such as pages visited and time spent on each page.</>
                                        : selectedLanguage === 'tr' ? <><strong>Kullanım Verileri:</strong> Web sitemizi nasıl kullandığınıza dair bilgiler, ziyaret edilen sayfalar ve her sayfada geçirilen süre gibi.</>
                                            : selectedLanguage === 'fa' ? <><strong>اطلاعات استفاده:</strong> اطلاعاتی در مورد نحوه استفاده شما از وب‌سایت ما، مانند صفحات بازدید شده و زمان صرف شده در هر صفحه.</>
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? <><strong>Cookies:</strong> Small data files stored on your device to enhance your browsing experience.</>
                                        : selectedLanguage === 'tr' ? <><strong>Çerezler:</strong> Tarama deneyiminizi geliştirmek için cihazınıza kaydedilen küçük veri dosyaları.</>
                                            : selectedLanguage === 'fa' ? <><strong>کوکی‌ها:</strong> فایل‌های کوچک داده‌ای که بر روی دستگاه شما ذخیره می‌شوند تا تجربه مرور شما را بهبود بخشند.</>
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'How We Use Your Information'
                                    : selectedLanguage === 'tr' ? 'Bilgilerinizi Nasıl Kullanıyoruz'
                                        : selectedLanguage === 'fa' ? 'چگونه از اطلاعات شما استفاده می‌کنیم'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'We use your personal information to:'
                                    : selectedLanguage === 'tr' ? 'Kişisel bilgilerinizi şu amaçlarla kullanıyoruz:'
                                        : selectedLanguage === 'fa' ? 'ما از اطلاعات شخصی شما برای اهداف زیر استفاده می‌کنیم:'
                                            : null
                                }
                            </span>
                        </p>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Provide and improve our services.'
                                        : selectedLanguage === 'tr' ? 'Hizmetlerimizi sunmak ve geliştirmek.'
                                            : selectedLanguage === 'fa' ? 'ارائه و بهبود خدمات ما.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Communicate with you about updates, offers, and promotions.'
                                        : selectedLanguage === 'tr' ? 'Güncellemeler, teklifler ve promosyonlar hakkında sizinle iletişim kurmak.'
                                            : selectedLanguage === 'fa' ? 'ارتباط با شما در مورد به‌روزرسانی‌ها، پیشنهادات و تبلیغات.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Respond to your inquiries and provide customer support.'
                                        : selectedLanguage === 'tr' ? 'Sorgularınıza yanıt vermek ve müşteri desteği sağlamak.'
                                            : selectedLanguage === 'fa' ? 'پاسخ به درخواست‌های شما و ارائه پشتیبانی مشتری.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Analyze website usage to enhance user experience.'
                                        : selectedLanguage === 'tr' ? 'Kullanıcı deneyimini geliştirmek için web sitesi kullanımını analiz etmek.'
                                            : selectedLanguage === 'fa' ? 'تجزیه و تحلیل استفاده از وب‌سایت برای بهبود تجربه کاربر.'
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Sharing Your Information'
                                    : selectedLanguage === 'tr' ? 'Bilgilerinizi Paylaşma'
                                        : selectedLanguage === 'fa' ? 'به اشتراک گذاشتن اطلاعات شما'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'We do not share your personal information with third parties, except:'
                                    : selectedLanguage === 'tr' ? 'Kişisel bilgilerinizi üçüncü taraflarla paylaşmıyoruz, yalnızca:'
                                        : selectedLanguage === 'fa' ? 'ما اطلاعات شخصی شما را با اشخاص ثالث به اشتراک نمی‌گذاریم، مگر:'
                                            : null
                                }
                            </span>
                        </p>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'When required by law or to protect our rights.'
                                        : selectedLanguage === 'tr' ? 'Yasal olarak zorunlu olduğunda veya haklarımızı korumak için.'
                                            : selectedLanguage === 'fa' ? 'وقتی که قانونا لازم باشد یا برای محافظت از حقوق ما.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'With service providers who assist us in operating our website and services.'
                                        : selectedLanguage === 'tr' ? 'Web sitemizi ve hizmetlerimizi işletmemize yardımcı olan hizmet sağlayıcılarla.'
                                            : selectedLanguage === 'fa' ? 'با ارائه‌دهندگان خدماتی که به ما در اداره وب‌سایت و خدمات ما کمک می‌کنند.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'If we merge with or are acquired by another company.'
                                        : selectedLanguage === 'tr' ? 'Başka bir şirketle birleşir veya başka bir şirket tarafından satın alınırsa.'
                                            : selectedLanguage === 'fa' ? 'اگر با شرکت دیگری ادغام شویم یا توسط شرکت دیگری خریداری شویم.'
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Data Security'
                                    : selectedLanguage === 'tr' ? 'Veri Güvenliği'
                                        : selectedLanguage === 'fa' ? 'امنیت داده‌ها'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'We implement security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.'
                                    : selectedLanguage === 'tr' ? 'Kişisel bilgilerinizi korumak için güvenlik önlemleri uyguluyoruz. Ancak, İnternet üzerinden iletim veya elektronik depolama yönteminin %100 güvenli olmadığını unutmayın.'
                                        : selectedLanguage === 'fa' ? 'ما اقدامات امنیتی را برای محافظت از اطلاعات شخصی شما اجرا می‌کنیم. با این حال، هیچ روش انتقال از طریق اینترنت یا ذخیره‌سازی الکترونیکی 100٪ ایمن نیست.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Your Rights'
                                    : selectedLanguage === 'tr' ? 'Haklarınız'
                                        : selectedLanguage === 'fa' ? 'حقوق شما'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'You have the right to:'
                                    : selectedLanguage === 'tr' ? 'Aşağıdaki haklara sahipsiniz:'
                                        : selectedLanguage === 'fa' ? 'شما حق دارید:'
                                            : null
                                }
                            </span>
                        </p>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Access and update your personal information.'
                                        : selectedLanguage === 'tr' ? 'Kişisel bilgilerinize erişme ve güncelleme yapma.'
                                            : selectedLanguage === 'fa' ? 'دسترسی و به‌روزرسانی اطلاعات شخصی شما.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Request deletion of your personal data.'
                                        : selectedLanguage === 'tr' ? 'Kişisel verilerinizin silinmesini talep etme.'
                                            : selectedLanguage === 'fa' ? 'درخواست حذف داده‌های شخصی شما.'
                                                : null
                                    }
                                </span>
                            </li>
                            <li className='tc-content-item'>
                                <span>
                                    {selectedLanguage === 'en' ? 'Opt-out of receiving marketing communications.'
                                        : selectedLanguage === 'tr' ? 'Pazarlama iletişimlerinden çıkma.'
                                            : selectedLanguage === 'fa' ? 'انصراف از دریافت ارتباطات بازاریابی.'
                                                : null
                                    }
                                </span>
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Changes to This Policy'
                                    : selectedLanguage === 'tr' ? 'Bu Politikadaki Değişiklikler'
                                        : selectedLanguage === 'fa' ? 'تغییرات در این سیاست'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'We may update this privacy policy from time to time. Any changes will be posted on this page.'
                                    : selectedLanguage === 'tr' ? 'Bu gizlilik politikasını zaman zaman güncelleyebiliriz. Herhangi bir değişiklik bu sayfada yayınlanacaktır.'
                                        : selectedLanguage === 'fa' ? 'ممکن است این سیاست حفظ حریم خصوصی را از زمان به زمان به‌روزرسانی کنیم. هرگونه تغییر در این صفحه اعلام خواهد شد.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            <span>
                                {selectedLanguage === 'en' ? 'Contact Us'
                                    : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                        : selectedLanguage === 'fa' ? 'تماس با ما'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? `If you have any questions about this privacy policy, please contact us at ${contactEmail}.`
                                    : selectedLanguage === 'tr' ? `Bu gizlilik politikası hakkında herhangi bir sorunuz varsa, lütfen bizimle şu adresten iletişime geçin: ${contactEmail}.`
                                        : selectedLanguage === 'fa' ? `اگر در مورد این سیاست حفظ حریم خصوصی سوالی دارید، لطفاً از طریق ${contactEmail} با ما تماس بگیرید.`
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                    <article className='tc-content'>
                        <p className='tc--content-description'>
                            <span>
                                {selectedLanguage === 'en' ? 'This policy is effective as of 2024.'
                                    : selectedLanguage === 'tr' ? 'Bu politika 2024 yılı itibariyle geçerlidir.'
                                        : selectedLanguage === 'fa' ? 'این سیاست از سال 2024 موثر است.'
                                            : null
                                }
                            </span>
                        </p>
                    </article>

                </div>
            </section>
        </div>
    );
}

export default PrivacyPolicy;