import React from 'react';
import './Four O Four.css'

function FourOFour() {




    return (
        <div className='four-O-four_section'>
            <h1 className='four-O-four_title'>
                404
            </h1>
        </div>


    );
}

export default FourOFour;




