import React, { useRef, useState, useEffect } from 'react';
import './Consultation Form.css'; // Ensure the CSS file name matches
import { FaPaperPlane } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import emailjs from '@emailjs/browser';

function ConsultationForm({selectedLanguage}) {
    const [showMessage, setShowMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submissions, setSubmissions] = useState([]);
    const form = useRef();
    const [errorMessage, setErrorMessage] = useState('');


    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_phone: '',
        user_message: '',
        user_service: '',
    });

    const sendEmail = (e) => {
        e.preventDefault();
        setIsLoading(true);

        emailjs.sendForm('service_1qwaduh', 'template_izvrm5i', form.current, 'qoQqXCBRWR0-CiGfM')
            .then((result) => {
                console.log(result.text);
                setFormData({
                    user_name: '',
                    user_email: '',
                    user_phone: '',
                    user_message: '',
                    user_service: '',
                });
                handleShowMessage();
                logSubmission();
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                setErrorMessage('Failed to send the email. Please try again later.');

            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleShowMessage = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
            setFormData({
                user_name: '',
                user_email: '',
                user_phone: '',
                user_message: '',
                user_service: '',
            });
        }, 3000);
    };

    useEffect(() => {
        setSelectedOption(
            selectedLanguage === 'en' ? 'Service Type'
                : selectedLanguage === 'tr' ? 'Hizmet Türü'
                    : selectedLanguage === 'fa' ? 'نوع خدمات'
                        : ''
        );
        setIsOptionSelected(false);
    }, [selectedLanguage]);


    const logSubmission = () => {
        setSubmissions(prevSubmissions => [
            ...prevSubmissions,
            { ...formData, timestamp: new Date().toLocaleString() }
        ]);
    };

    const [selectedOption, setSelectedOption] = useState(
        selectedLanguage === 'en' ? 'Service Type'
            : selectedLanguage === 'tr' ? 'Hizmet Türü'
                : selectedLanguage === 'fa' ? 'نوع خدمات'
                    : null
    );
    const [isOpen, setIsOpen] = useState(false);
    const customSelectRef = useRef(null);
    const [isOptionSelected, setIsOptionSelected] = useState(false);


    const handleOptionClick = (option) => {
        setSelectedOption(option.value[selectedLanguage]); // Set the selected option label in the current language
        setFormData((prevData) => ({
            ...prevData,
            user_service: option.value[selectedLanguage], // Use the value based on the selected language
        }));
        setIsOpen(false);
        setIsOptionSelected(true);
    };

    const handleClickOutside = (event) => {
        if (customSelectRef.current && !customSelectRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const options = [
        {
            value: {
                en: "web_development",
                tr: "web_geliştirme",
                fa: "توسعه وب"
            },
            label: "Web Development"
        },
        {
            value: {
                en: "web_design",
                tr: "web_tasarımı",
                fa: "طراحی وب"
            },
            label: "Web Design"
        },
        {
            value: {
                en: "seo",
                tr: "seo",
                fa: "سئو"
            },
            label: "SEO"
        },
        {
            value: {
                en: "content_creation",
                tr: "içerik_oluşturma",
                fa: "ایجاد محتوا"
            },
            label: "Content Creation"
        },
        {
            value: {
                en: "digital_marketing",
                tr: "dijital_pazarlama",
                fa: "بازاریابی دیجیتال"
            },
            label: "Digital Marketing"
        }
    ];

    const darClass = selectedLanguage === 'fa' ? 'dari' : '';

    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>

            <section className="consultation-section">
                <div className="consultation-container container">
                    <div className="consultation-data">
                        <h3 className="consultation-title section_title-2">
                            <span>
                                {selectedLanguage === 'en' ? 'Request Consultation'
                                    : selectedLanguage === 'tr' ? 'Danışmanlık Talebinde Bulunun'
                                        : selectedLanguage === 'fa' ? 'درخواست مشاوره'
                                            : null}
                            </span>
                        </h3>

                        <p className="consultation-description-1">
                            {selectedLanguage === 'en' ? 'Fill out this short form and a Nacode representative will contact you within the same business day.'
                                : selectedLanguage === 'tr' ? 'Bu kısa formu doldurun, bir Nacode temsilcisi aynı iş günü içerisinde sizinle iletişime geçecektir.'
                                    : selectedLanguage === 'fa' ? 'این فرم کوتاه را پر کنید و نماینده Nacode در همان روز کاری با شما تماس خواهد گرفت.'
                                        : null}
                        </p>
                        <p className="consultation-description-2">
                           
                            {selectedLanguage === 'en' ? 'For all virtual consultations, we will contact you to schedule a day and time. For Google Meet, please be sure the email address provided is associated with your Google account.'
                                : selectedLanguage === 'tr' ? 'Tüm sanal danışmanlıklar için sizinle iletişime geçerek bir gün ve saat belirleyeceğiz. Google Meet için lütfen verilen e-posta adresinin Google hesabınızla ilişkili olduğundan emin olun.'
                                    : selectedLanguage === 'fa' ? 'برای تمام مشاوره‌های مجازی، با شما تماس خواهیم گرفت تا یک روز و زمان را تنظیم کنیم. برای Google Meet، لطفاً اطمینان حاصل کنید که آدرس ایمیلی که ارائه داده‌اید با حساب Google شما مرتبط است.'
                                        : null}
                        </p>
                        <div className="geometric_box"></div>
                    </div>

                    <div className="consultation-form-container">
                        <form className="consultation-form" ref={form} onSubmit={sendEmail}>
                            <div className="consultation-group">
                                <div className="consultation-box">
                                    <input
                                        type="text"
                                        name="user_name"
                                        className="consultation-input"
                                        value={formData.user_name}
                                        onChange={handleInputChange}
                                        placeholder={
                                            selectedLanguage === 'en' ? 'Full Name'
                                                : selectedLanguage === 'tr' ? 'Ad Soyad'
                                                    : selectedLanguage === 'fa' ? 'نام کامل'
                                                        : null}
                                        required
                                        lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}
                                        disabled={isLoading}  // Add this line
                                    />
                                    <label htmlFor="user_name" className={`consultation-label ${darClass}`} >
                                        {selectedLanguage === 'en' ? 'Full Name'
                                            : selectedLanguage === 'tr' ? 'Ad Soyad'
                                                : selectedLanguage === 'fa' ? 'نام کامل'
                                                    : null}
                                    </label>
                                </div>
                                <div className="consultation-box">
                                    <input
                                        type="email"
                                        name="user_email"
                                        className="consultation-input"
                                        value={formData.user_email}
                                        onChange={handleInputChange}
                                        placeholder={
                                            selectedLanguage === 'en' ? 'Email Address'
                                                : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                                    : selectedLanguage === 'fa' ? 'آدرس ایمیل'
                                                        : null}
                                        required
                                        lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}
                                    />
                                    <label htmlFor="user_email" className={`consultation-label ${darClass}`}>
                                        {selectedLanguage === 'en' ? 'Email Address'
                                            : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                                : selectedLanguage === 'fa' ? 'آدرس ایمیل'
                                                    : null}
                                    </label>
                                </div>
                            </div>
                            <div className="consultation-box">
                                <input
                                    type="tel"
                                    name="user_phone"
                                    className="consultation-input"
                                    value={formData.user_phone}
                                    onChange={handleInputChange}
                                    placeholder={
                                        selectedLanguage === 'en' ? 'Phone Number'
                                            : selectedLanguage === 'tr' ? 'Telefon Numarası'
                                                : selectedLanguage === 'fa' ? 'شماره تلفن'
                                                    : null}
                                    required
                                    lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}
                                />
                                <label htmlFor="user_phone" className={`consultation-label ${darClass}`} >
                                    {selectedLanguage === 'en' ? 'Phone Number'
                                        : selectedLanguage === 'tr' ? 'Telefon Numarası'
                                            : selectedLanguage === 'fa' ? 'شماره تلفن'
                                                : null}
                                </label>
                            </div>

                            <div className={`consultation-box ${selectedOption !==
                            (selectedLanguage === 'en' ? 'Service Type'
                                : selectedLanguage === 'tr' ? 'Hizmet Türü'
                                    : selectedLanguage === 'fa' ? 'نوع خدمات'
                                        : '') ? 'selected' : ''}`}>

                                <div
                                    className="consultation-input custom-select-input"
                                    ref={customSelectRef}>
                                    <div
                                        name="user_service"
                                        className={`custom-select ${isOpen ? 'open' : ''} ${isOptionSelected ? 'selected' : ''}`}
                                        onClick={() => setIsOpen(!isOpen)}
                                        aria-haspopup="listbox"
                                        aria-expanded={isOpen}
                                        role="combobox"
                                        aria-label={selectedLanguage === 'en' ? 'Service Type'
                                            : selectedLanguage === 'tr' ? 'Hizmet Türü'
                                                : selectedLanguage === 'fa' ? 'نوع خدمات'
                                                    : null}
                                    >
                                        {selectedOption}
                                    </div>
                                    {isOpen && (
                                        <ul className="custom-options" role="listbox">
                                            {options.map(option => (
                                                <li
                                                    key={option.value.en} // Using 'en' as the unique key
                                                    className="custom-option"
                                                    onClick={() => handleOptionClick(option)}
                                                    role="option"
                                                    aria-selected={selectedOption === option.value[selectedLanguage]}
                                                >
                                                    {option.value[selectedLanguage]} {/* Display the label in the selected language */}
                                                </li>
                                            ))}

                                        </ul>
                                    )}
                                </div>
                                <label
                                    htmlFor="user_service"
                                    className={`custom-select-label ${darClass} ${selectedOption !==
                                    (selectedLanguage === 'en' ? 'Service Type'
                                        : selectedLanguage === 'tr' ? 'Hizmet Türü'
                                            : selectedLanguage === 'fa' ? 'نوع خدمات'
                                                : '') ? 'active red' : ''}`}
                                >
                                    {selectedLanguage === 'en' ? 'Service Type'
                                        : selectedLanguage === 'tr' ? 'Hizmet Türü'
                                            : selectedLanguage === 'fa' ? 'نوع خدمات'
                                                : null}
                                </label>
    
                            </div>

                            <div className="consultation-box consultation-area">
                                <textarea
                                    className="consultation-input"
                                    name="user_message"
                                    id="message"
                                    required
                                    placeholder=
                                        {selectedLanguage === 'en' ? 'Message'
                                            : selectedLanguage === 'tr' ? 'Mesaj'
                                                : selectedLanguage === 'fa' ? 'پیام'
                                                    : null}
                                    value={formData.user_message}
                                    onChange={handleInputChange}
                                    lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}
                                ></textarea>
                                <label htmlFor="user_message" className={`consultation-label ${darClass}`}>
                                    {selectedLanguage === 'en' ? 'Message'
                                        : selectedLanguage === 'tr' ? 'Mesaj'
                                            : selectedLanguage === 'fa' ? 'پیام'
                                                : null}
                                </label>
                            </div>
                            <input
                                type="hidden"
                                name="user_service"
                                value={formData.user_service}
                            />
                            <p className="consultation-message" id="consultation_message"></p>
                            <button className="button" type="submit" disabled={isLoading} lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'} >
                                {isLoading ? (
                                    selectedLanguage === 'en' ? 'Sending...'
                                        : selectedLanguage === 'tr' ? 'Gönderiliyor...'
                                            : selectedLanguage === 'fa' ? 'در حال ارسال...'
                                                : 'Sending...'
                                ) : (
                                    <>
                                        <FaPaperPlane />
                                        {selectedLanguage === 'en' ? 'Send'
                                            : selectedLanguage === 'tr' ? 'Gönder'
                                                : selectedLanguage === 'fa' ? 'ارسال'
                                                    : ''}
                                    </>
                                )}

                            </button>
                            {showMessage && (
                                <div className="consultation-popup">
                                    <p className="consultation-popup-text">
                                        Message sent! <TiTick className='consultation-popup-icon'/>
                                    </p>
                                </div>
                            )}

                            {errorMessage && <p className="error-message">{errorMessage}</p>}

                        </form>
                        {submissions.length > 0 && (
                            <div className="submissions-log">
                                <h4>Consultation Requests Log</h4>
                                <ul>
                                    {submissions.map((submission, index) => (
                                        <li key={index}>
                                            <strong>{submission.user_name}</strong> requested <strong>{submission.user_service}</strong> on {submission.timestamp}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ConsultationForm;