import React from "react";
import './Term&Condition.css';

function TermCondition({selectedLanguage}) {
    return (
        <div className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="term-condition-section section">
                <div className='container term-condition-container'>
                    <h1 className="section_title-1 term-condition-title">
                        {selectedLanguage === 'en' ? 'Terms and Conditions'
                            : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                : selectedLanguage === 'fa' ? 'با ما تماس بگیرید'
                                    : null
                        }
                    </h1>
                </div>

                <div className="tc-contents container">
                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            {selectedLanguage === 'en' ? 'Introduction'
                                : selectedLanguage === 'tr' ? 'Giriş'
                                    : selectedLanguage === 'fa' ? 'مقدمه'
                                        : null
                            }
                        </h2>
                        <p className='tc--content-description'>
                            {selectedLanguage === 'en' ? 'Welcome to Nacode! By using our website located at www.nacode.co, you agree to comply with and be bound by these terms and conditions. If you do not agree with these terms, please do not use our website.'
                            : selectedLanguage === 'tr' ? 'Nacode\'a hoş geldiniz! www.nacode.co adresindeki web sitemizi kullanarak, bu şartlar ve koşullara uymayı ve bunlarla bağlı olmayı kabul etmiş olursunuz. Bu şartları kabul etmiyorsanız, lütfen web sitemizi kullanmayın.'
                                : selectedLanguage === 'fa' ? 'به ناکد خوش آمدید! با استفاده از وب سایت ما در www.nacode.co، شما موافقت می کنید که با این شرایط و ضوابط رعایت کنید و به آنها متعهد باشید. اگر با این شرایط موافق نیستید، لطفاً از وب سایت ما استفاده نکنید.'
                                    : null
                        }
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            {selectedLanguage === 'en' ? 'Services'
                                : selectedLanguage === 'tr' ? 'Hizmetler'
                                    : selectedLanguage === 'fa' ? 'خدمات'
                                        : null
                            }
                        </h2>
                        <p className='tc--content-description'>
                            {selectedLanguage === 'en' ? 'We provide web design and development services. Specific terms and conditions for our services are detailed in individual service agreements.'
                                : selectedLanguage === 'tr' ? 'Web tasarım ve geliştirme hizmetleri sunuyoruz. Hizmetlerimize ilişkin özel şartlar ve koşullar, bireysel hizmet sözleşmelerinde ayrıntılı olarak belirtilmiştir.'
                                    : selectedLanguage === 'fa' ? 'ما خدمات طراحی و توسعه وب ارائه می دهیم. شرایط و ضوابط خاص خدمات ما در قراردادهای خدمات جداگانه شرح داده شده است.'
                                        : null
                            }
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            {selectedLanguage === 'en' ? 'User Conduct'
                                : selectedLanguage === 'tr' ? 'Kullanıcı Davranışı'
                                    : selectedLanguage === 'fa' ? 'رفتار کاربر'
                                        : null
                            }
                        </h2>
                        <p className='tc--content-description'>
                            {selectedLanguage === 'en' ? 'You agree to use our website for lawful purposes only. You must not:'
                                : selectedLanguage === 'tr' ? 'Web sitemizi yalnızca yasal amaçlar için kullanmayı kabul edersiniz. Şunları yapmamalısınız:'
                                    : selectedLanguage === 'fa' ? 'شما موافقت می کنید که از وب سایت ما فقط برای اهداف قانونی استفاده کنید. شما نباید:'
                                        : null
                            }
                        </p>
                        <ul className='tc-content-list'>
                            <li className='tc-content-item'>
                                {selectedLanguage === 'en' ? 'Post or transmit any harmful or illegal content'
                                    : selectedLanguage === 'tr' ? 'Zararlı veya yasa dışı içerik göndermek veya iletmek'
                                        : selectedLanguage === 'fa' ? 'هرگونه محتوای مضر یا غیرقانونی ارسال یا انتقال دهید'
                                            : null
                                }
                            </li>
                            <li className='tc-content-item'>
                                {selectedLanguage === 'en' ? 'Use the website to engage in any fraudulent activity'
                                    : selectedLanguage === 'tr' ? 'Herhangi bir dolandırıcılık faaliyetine katılmak için web sitesini kullanın'
                                        : selectedLanguage === 'fa' ? 'از وب سایت برای انجام هرگونه فعالیت متقلبانه استفاده کنید'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Attempt to gain unauthorized access to any part of the website'
                                    : selectedLanguage === 'tr' ? 'Web sitesinin herhangi bir bölümüne yetkisiz erişim sağlamaya çalışmak'
                                        : selectedLanguage === 'fa' ? 'تلاش برای دسترسی غیرمجاز به هر بخشی از وب سایت'
                                            : null
                                }
                            </li>
                        </ul>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            {selectedLanguage === 'en' ? 'Liability'
                                : selectedLanguage === 'tr' ? 'Sorumluluk'
                                    : selectedLanguage === 'fa' ? 'مسئولیت'
                                        : null
                            }
                        </h2>

                        <p className='tc--content-description'>
                            {selectedLanguage === 'en' ? 'We strive to ensure the accuracy of the information on our website, but we do not guarantee it. We are not liable for any damages arising from the use of our website.'
                                : selectedLanguage === 'tr' ? 'Web sitemizdeki bilgilerin doğruluğunu sağlamaya çalışıyoruz, ancak bunu garanti etmiyoruz. Web sitemizin kullanımından kaynaklanan herhangi bir zarardan sorumlu değiliz.'
                                    : selectedLanguage === 'fa' ? 'ما تلاش می کنیم تا از صحت اطلاعات موجود در وب سایت خود اطمینان حاصل کنیم، اما آن'
                                        : null 
                            }
                        </p>
                    </article>

                    <article className='tc-content'>
                        <h2 className='tc-content-title '>
                            {selectedLanguage === 'en' ? 'Changes to Terms'
                                : selectedLanguage === 'tr' ? 'Şartlardaki Değişiklikler'
                                    : selectedLanguage === 'fa' ? 'تغییرات شرایط'
                                        : null
                            }
                        </h2>
                        <p className='tc--content-description'>
                            {selectedLanguage === 'en' ? 'We may update these terms and conditions from time to time. The latest version will always be available on our website.'
                                : selectedLanguage === 'tr' ? 'Bu şartlar ve koşulları zaman zaman güncelleyebiliriz. En son sürüm her zaman web sitemizde mevcut olacaktır.'
                                    : selectedLanguage === 'fa' ? 'ما ممکن است هر از گاهی این شرایط و ضوابط را به‌روزرسانی کنیم. آخرین نسخه همیشه در وب سایت ما موجود خواهد بود.'
                                        : null
                            }
                        </p>
                    </article>

                </div>


            </section>
        </div>
    )
}

export default TermCondition;