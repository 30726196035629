import React, {useState} from 'react'
import './Home.css';
import { FaArrowUp } from "react-icons/fa";
import HomeImg from '../../assest/Home Image.png';

import {Link} from "react-router-dom";


function Home({scrollToTop, selectedLanguage}) {

    {
        /*
        const [projectsList] = useState(ProjectsList);
    const [selectedProject, setSelectedProject] = useState(null);

    const view = (project) => {
        setSelectedProject(project);
    };

    const close = () => {
        setSelectedProject(null);
    };
         */
    }
    
    return (

        <main className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="home_section" id="home">
                <div className="home_container container grid">

                    <h1 className="home_name">
                        
                        {selectedLanguage === 'en' ? 'DESIGN THAT ELEVATES YOUR DIGITAL PRESENCE'
                            : selectedLanguage === 'tr' ? 'DİJİTAL VARLIĞINIZI YÜKSELTEN TASARIM'
                                : selectedLanguage === 'fa' ? 'طراحی که حضور دیجیتال شما را ارتقا می‌بخشد'
                                    : null
                        }
                    </h1>

                    <div className="home_images">
                        <img src={HomeImg} alt="pic of home" className="home_img"/>
                    </div>

                    <div className="home_info">
                        <Link to='/about' className="home_scroll">
                            <div className="home_scroll-box" onClick={scrollToTop}>
                                <FaArrowUp className='scroll-down-arrow'/>
                            </div>
                            <span className="home_scroll-text">
                                {selectedLanguage === 'en' ? 'About Us'
                                    : selectedLanguage === 'tr' ? 'Hakkımızda'
                                        : selectedLanguage === 'fa' ? 'درباره ما'
                                            : null
                                }
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default Home;



















