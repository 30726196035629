import React, { useState, useEffect, useCallback, memo } from 'react';
import './Project Details.css';
import { useParams } from 'react-router-dom';
import { ProjectsList } from "../../Projects List";
import { RecentProjectsList } from "../../Recent Projects List";

import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import { HiOutlineMinusSmall } from "react-icons/hi2";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import AlertModal from '../Alert Modal/AlertModal';

function ProjectDetails({ selectedLanguage }) {
    const [projectDetails, setProjectDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        const combinedProjectsList = [...ProjectsList, ...RecentProjectsList];
        const details = combinedProjectsList.find(project => project.id === parseInt(id));
        setProjectDetails(details);
    }, [id]);

    useEffect(() => {
        if (projectDetails) {
            setIsLoading(false);
        }
    }, [projectDetails]);

    const handleLivePreviewClick = (e) => {
        if (!projectDetails.livePreview) {
            e.preventDefault();
            setShowAlert(true);
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    // slider
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    const handlePrevClick = () => {
        const isFirstSlide = currentImageIndex === 0;
        const newIndex = isFirstSlide ? projectDetails.img.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(newIndex);
    };

    const handleNextClick = () => {
        const isLastSlide = currentImageIndex === projectDetails.img.length - 1;
        const newIndex = isLastSlide ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(newIndex);
    };

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches ? e.targetTouches[0].clientX : e.clientX);
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        setTouchEnd(e.targetTouches ? e.targetTouches[0].clientX : e.clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const minSwipeDistance = 50;

        if (distance > minSwipeDistance) {
            handleNextClick();
        } else if (distance < -minSwipeDistance) {
            handlePrevClick();
        }

        setTouchStart(null);
        setTouchEnd(null);
        setIsDragging(false);
    };

    const handleMouseDown = (e) => {
        handleTouchStart(e);
    };

    const handleMouseMove = (e) => {
        handleTouchMove(e);
    };

    const handleMouseUp = () => {
        handleTouchEnd();
    };

    const handleMouseLeave = () => {
        if (isDragging) handleTouchEnd();
    };

    // Disable right-click context menu on images and the main container
    const preventImageCopy = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        document.addEventListener('contextmenu', preventImageCopy);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('contextmenu', preventImageCopy);
        };
    }, []);

    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} >
            <section className='pd_section'>
                {isLoading ? (
                    <div className='row_loader-container'>
                        <span className='loader'></span>
                    </div>
                ) : (
                    <div className='pd_container grid container'>
                        {projectDetails ? (
                            <div className='pd_content'>

                                <div className="productDetails-slider-container">

                                    <div className='product-details-info'>
                                        <h2 className="pd_title">
                                            {selectedLanguage === 'en' ? projectDetails.title.en
                                                : selectedLanguage === 'tr' ? projectDetails.title.tr
                                                    : selectedLanguage === 'fa' ? projectDetails.title.fa
                                                        : null}
                                        </h2>

                                        <div className="pd_buttons">
                                            <a
                                                href={projectDetails.livePreview || "#"}
                                                className="pd-live-link button"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={handleLivePreviewClick}
                                            >
                                                <BsFillGrid1X2Fill className="github_icon"/> Live Preview
                                            </a>
                                        </div>

                                        <p className="details-info-description">
                                            {selectedLanguage === 'en' ? projectDetails.description.en
                                                : selectedLanguage === 'tr' ? projectDetails.description.tr
                                                    : selectedLanguage === 'fa' ? projectDetails.description.fa
                                                        : null}
                                        </p>
                                    </div>

                                    <div
                                        className="slider-container"
                                        onTouchStart={handleTouchStart}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                        onMouseDown={handleMouseDown}
                                        onMouseMove={handleMouseMove}
                                        onMouseUp={handleMouseUp}
                                        onMouseLeave={handleMouseLeave}
                                        style={{ userSelect: 'none', WebkitUserSelect: 'none' }} // Disable text/image selection
                                    >
                                        <div className="slider">
                                            <button className="prev-button" onClick={handlePrevClick}>
                                                <IoIosArrowBack className='details-slider-icon'/>
                                            </button>
                                            <img
                                                src={projectDetails.img[currentImageIndex]}
                                                alt={`Slide ${currentImageIndex + 1}`}
                                                className="slider-image"
                                                draggable="false"
                                                onContextMenu={preventImageCopy} // Prevent context menu on image
                                                style={{ WebkitTouchCallout: 'none' }} // Disable long press on mobile
                                            />
                                            <button className="next-button" onClick={handleNextClick}>
                                                <IoIosArrowForward className='details-slider-icon'/>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div className='pd_details'
                                     dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}
                                >
                                    {showAlert &&
                                        <AlertModal
                                            message="Live preview is not available for this project."
                                            onClose={closeAlert}
                                        />
                                    }

                                    <h2 className='feature_title'>
                                        {selectedLanguage === 'en' ? 'Features'
                                            : selectedLanguage === 'tr' ? 'Özellikler'
                                                : selectedLanguage === 'fa' ? 'ویژگی ها'
                                                    : null
                                        }
                                        <HiOutlineMinusSmall/>
                                    </h2>
                                    <ul className='pd_feature-list'>
                                        {projectDetails?.feature && selectedLanguage && (
                                            projectDetails.feature[selectedLanguage]?.map((feature, index) => (
                                                <li className='pd_feature-item' key={index}>
                                                    <FaRegCircleCheck className='pd_feature-icon'/> <h4>{feature}</h4>
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <h1 className='services_title'>Product not found</h1>
                        )}
                    </div>
                )}
            </section>
        </main>
    );
}

export default memo(ProjectDetails);
