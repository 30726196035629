import React, {useState} from 'react'
import './Morocco.css';

function Home({scrollToTop}) {
    const [isHovered, setIsHovered] = useState(false);

    return (

        <main className="main">

            <section className="section ">

                <div className='container grid'>
                    <div className="morocco_flag-content">

                        <div className="morocco_tittle">
                            <h2 data-text="Chaymae ❤️ Morocco&nbsp;">
                                Chaymae 🖤 Morocco&nbsp;
                            </h2>
                        </div>

                        <div className="morocco_flag">
                            <div className="morocco_flag-red"
                                 onMouseEnter={() => setIsHovered(true)}
                                 onMouseLeave={() => setIsHovered(false)}
                            >
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Star_of_Morocco_(unbordered).svg/810px-Star_of_Morocco_(unbordered).svg.png"

                                    className={isHovered ? "morocco_flag-emblem morocco_flag-emblem-active" : "morocco_flag-emblem"}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </section>

        </main>
    );
}

export default Home;



















