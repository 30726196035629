import React, {useState, useRef, useEffect} from "react";
import './My Skill.css';
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { DiJavascript } from "react-icons/di";
import { FaBootstrap } from "react-icons/fa";
import { FaSass } from "react-icons/fa";
import { GrReactjs } from "react-icons/gr";
import { TbBrandNextjs } from "react-icons/tb";
import { SiRedux } from "react-icons/si";
import { BiLogoStripe } from "react-icons/bi";
import { IoLogoFirebase } from "react-icons/io5";
import { DiMongodb } from "react-icons/di";
import { FaNodeJs } from "react-icons/fa";
import { FaGitAlt } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { SiAdobe } from "react-icons/si";
import { SiNetlify } from "react-icons/si";
import { SiRender } from "react-icons/si";
import { SiCanva } from "react-icons/si";
import { SiJson } from "react-icons/si";


function Skill({selectedLanguage}) {
    const [selectedIcon, setSelectedIcon] = useState(null);
    const iconsContainerRef = useRef(null);

    const handleIconClick = (name) => {
        setSelectedIcon(name);
    };

    const handleClickOutside = (event) => {
        if (iconsContainerRef.current && !iconsContainerRef.current.contains(event.target)) {
            setSelectedIcon(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return (
        <section className='skill_section ' id='skill' lang={selectedLanguage === 'fa' ? 'fa' : 'en'}>
            <div className="skill_container  grid">
                <div className='skill_data'>
                    <h3 className='section_title-2 skill_tittle'>
                        {selectedLanguage === 'en' ? 'Technologies'
                            : selectedLanguage === 'tr' ? 'Teknolojiler'
                                : selectedLanguage === 'fa' ? 'تکنالوژی‌ها'
                                    : null
                        }
                    </h3>
                    <p className='skill_description'>
                        {selectedLanguage === 'en' ? 'Some of the newest technology that we use.'
                            : selectedLanguage === 'tr' ? 'Kullandığımız en yeni teknolojilerden bazıları.'
                                : selectedLanguage === 'fa' ? 'برخی از جدیدترین فناوری‌هایی که ما استفاده می‌کنیم.'
                                    : null
                        }
                    </p>
                    <div className="geometric_box"></div>
                </div>

                <div className='skill_content'>

                    <div className='skill_icons' ref={iconsContainerRef}>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Adobe')}>
                            {selectedIcon === 'Adobe' && <p className="skill_name">Adobe</p>}
                            <SiAdobe className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('HTML5')}>
                            {selectedIcon === 'HTML5' && <p className="skill_name">HTML5</p>}
                            <FaHtml5 className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('CSS3')}>
                            {selectedIcon === 'CSS3' && <p className="skill_name">CSS3</p>}
                            <FaCss3Alt className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Sass')}>
                            {selectedIcon === 'Sass' && <p className="skill_name">Sass</p>}
                            <FaSass className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('JavaScript')}>
                            {selectedIcon === 'JavaScript' && <p className="skill_name">JavaScript</p>}
                            <DiJavascript className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('JSON')}>
                            {selectedIcon === 'JSON' && <p className="skill_name">JSON</p>}
                            <SiJson className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('ReactJS')}>
                            {selectedIcon === 'ReactJS' && <p className="skill_name">ReactJS</p>}
                            <GrReactjs className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Bootstrap')}>
                            {selectedIcon === 'Bootstrap' && <p className="skill_name">Bootstrap</p>}
                            <FaBootstrap className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Next.js')}>
                            {selectedIcon === 'Next.js' && <p className="skill_name">Next.js</p>}
                            <TbBrandNextjs className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Redux')}>
                            {selectedIcon === 'Redux' && <p className="skill_name">Redux</p>}
                            <SiRedux className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Node.js')}>
                            {selectedIcon === 'Node.js' && <p className="skill_name">Node.js</p>}
                            <FaNodeJs className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Firebase')}>
                            {selectedIcon === 'Firebase' && <p className="skill_name">Firebase</p>}
                            <IoLogoFirebase className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('MongoDB')}>
                            {selectedIcon === 'MongoDB' && <p className="skill_name">MongoDB</p>}
                            <DiMongodb className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('GitHub')}>
                            {selectedIcon === 'GitHub' && <p className="skill_name">GitHub</p>}
                            <FaGithub className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Git')}>
                            {selectedIcon === 'Git' && <p className="skill_name">Git</p>}
                            <FaGitAlt className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Stripe')}>
                            {selectedIcon === 'Stripe' && <p className="skill_name">Stripe</p>}
                            <BiLogoStripe className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Netlify')}>
                            {selectedIcon === 'Netlify' && <p className="skill_name">Netlify</p>}
                            <SiNetlify className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Render')}>
                            {selectedIcon === 'Render' && <p className="skill_name">Render</p>}
                            <SiRender className='skill_icon'/>
                        </div>
                        <div className='skill_icon_container' onClick={() => handleIconClick('Canva')}>
                            {selectedIcon === 'Canva' && <p className="skill_name">Canva</p>}
                            <SiCanva className='skill_icon'/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Skill;




